<p-button
  label="戻る"
  class="nav-button"
  styleClass="p-button-secondary"
  icon="pi pi-arrow-left"
  iconPos="left"
  (click)="goBack()"
></p-button>
<p-button
  label="新規カテゴリ追加"
  class="nav-button"
  styleClass="p-button-primary"
  icon="pi pi-file"
  iconPos="left"
  (click)="showTypeDialog()"
></p-button>

<div class="grid">
  <div class="col-12"></div>
  <div class="col-1"></div>

  <div class="col-10">
    <p-fieldset *ngFor="let type of types">
      <ng-template pTemplate="header">{{ type.name }}</ng-template>
      <div class="grid">
        <div class="col-9">
          <ng-container *ngFor="let store of type.stores">
            <p-button
              [style]="{ margin: '0.3rem' }"
              label="{{ store.name }}"
              styleClass="p-button-success p-button-sm p-button-raised p-button-rounded"
              iconPos="right"
              pDraggable="{{ type.name }}"
              pDroppable="{{ type.name }}"
              (onDragStart)="dragStart(type, store)"
              (onDrop)="drop(store)"
              (onDragEnter)="dragEnter(store)"
              (onDragEnd)="dragEnd(store)"
              pTooltip="{{ tooltipMessage }}"
            ></p-button>
          </ng-container>
        </div>
        <div class="col-2">
          <p-button
            label="店舗追加"
            styleClass="p-button-primary p-button-sm p-button-raised p-button-rounded"
            iconPos="left"
            (click)="showStoreDialog(type.id)"
          ></p-button>
          <br />
          <br />
          <p-button
            label="店舗削除"
            styleClass="p-button-danger p-button-sm p-button-raised p-button-rounded"
            iconPos="left"
            icon="pi pi-trash"
            disabled="true"
            pDroppable="{{ type.name }}"
            (onDrop)="dropDeleteStore()"
          ></p-button>
          <br />
          <span *ngIf="canDeleteType(type)">
            <p-button
              label="カテゴリ削除"
              styleClass="p-button-danger p-button-sm p-button-raised p-button-rounded"
              iconPos="left"
              (click)="deleteType(type.id)"
            ></p-button
          ></span>
        </div>
        <div class="col-1 center">
          <button
            pButton
            pRipple
            *ngIf="!isFirstType(type)"
            (click)="movePrev(type)"
            type="button"
            icon="pi pi-arrow-up"
            class="p-button-rounded p-button-info"
          ></button>
          <br />
          <br />
          <button
            pButton
            pRipple
            *ngIf="!isLastType(type)"
            (click)="moveNext(type)"
            type="button"
            icon="pi pi-arrow-down"
            class="p-button-rounded p-button-info"
          ></button>
        </div>
      </div>
    </p-fieldset>
  </div>
  <div class="col-1"></div>
</div>

<p-dialog
  header="新規のカテゴリを追加"
  [(visible)]="displayTypeDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <br />
  <span class="p-float-label">
    <input
      id="float-input"
      type="text"
      pInputText
      [(ngModel)]="categoryName"
      style="width: 300px"
    />
    <label for="float-input">新規カテゴリ名</label>
  </span>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayTypeDialog = false"
      label="キャンセル"
      styleClass="p-button-danger"
    ></p-button>
    <p-button
      icon="pi pi-check"
      (click)="registerStoreType(categoryName)"
      label="登録"
      styleClass="p-button-primary"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="新規の店舗を追加"
  [(visible)]="displayStoreDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <br />
  <span class="p-float-label">
    <input
      id="float-input"
      type="text"
      pInputText
      [(ngModel)]="storeName"
      style="width: 300px"
    />
    <label for="float-input">新規の店舗名</label>
  </span>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayStoreDialog = false"
      label="キャンセル"
      styleClass="p-button-danger"
    ></p-button>
    <p-button
      icon="pi pi-check"
      (click)="registerStore()"
      label="登録"
      styleClass="p-button-primary"
    ></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  header="店舗削除確認"
  [(visible)]="displayDeleteStoreDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <b>{{ deleteStoreName }}</b> を削除してもよいでしょうか？
  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDeleteStoreDialog = false"
      label="キャンセル"
      styleClass="p-button-danger"
    ></p-button>
    <p-button
      icon="pi pi-check"
      (click)="deleteStore()"
      label="削除"
      styleClass="p-button-primary"
    ></p-button>
  </ng-template>
</p-dialog>
