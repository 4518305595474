import { Component, ElementRef, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Table } from "primeng/table";
import { IrisService } from "src/app/services/iris.service";
import { RoutingService } from "src/app/services/routing.service";
import { SubtitleService } from "src/app/services/subtitle.service";
import { UserService } from "src/app/services/user.service";
import { IrisOrderLevel, IrisProductQuery } from "src/gql/generated";

@Component({
  selector: "app-iris",
  templateUrl: "./iris.component.html",
  styleUrls: ["./iris.component.sass"],
})
export class IrisComponent implements OnInit {
  searchText: string;
  tableValue: any;
  form: UntypedFormGroup;
  irisProducts: Iris[];
  irisProductsAll: Iris[];
  selectedIris: Iris;
  profitValues = [0.0, 1.0];
  keyword: string;
  lastOrderLevel: string;
  showOldProducts = false;
  bigTypeDeliveryPrices = [
    589, 712, 815, 975, 1020, 1100, 1532, 1756, 2755, 3573, 4496, 5625,
  ];
  salesResultHeader: string[] = [];

  orderLevels = [
    {
      name: "直送",
      value: IrisOrderLevel.Direct,
    },
    {
      name: "直送(未販売)",
      value: IrisOrderLevel.DirectNoneSale,
    },
    {
      name: "直送-1",
      value: IrisOrderLevel.Direct1,
    },
    {
      name: "直送-3",
      value: IrisOrderLevel.Direct3,
    },
    {
      name: "直送-10",
      value: IrisOrderLevel.Direct10,
    },
    {
      name: "直送-10以上",
      value: IrisOrderLevel.DirectOver10,
    },
    {
      name: "FBA",
      value: IrisOrderLevel.Fba,
    },
    {
      name: "FBA検討",
      value: IrisOrderLevel.FbaConsider,
    },
    {
      name: "利益なし",
      value: IrisOrderLevel.NoneProfit,
    },
    {
      name: "在庫なし",
      value: IrisOrderLevel.NoneStock,
    },
    {
      name: "カタログなし",
      value: IrisOrderLevel.NoneCatalog,
    },
    {
      name: "廃番",
      value: IrisOrderLevel.Discontinued,
    },
    {
      name: "廃番予定",
      value: IrisOrderLevel.ScheduledDiscontinue,
    },
    {
      name: "その他",
      value: IrisOrderLevel.Other,
    },
  ];
  filterOrderLevels = [...this.orderLevels, { name: "-", value: "-" }];

  constructor(
    private elementRef: ElementRef,
    private routingService: RoutingService,
    private userService: UserService,
    private builer: UntypedFormBuilder,
    private subtitleService: SubtitleService,
    private irisService: IrisService
  ) {
    this.routingService.loginRequired();
    this.userService.irisRequired();
    this.subtitleService.setSubTitle("アイリス管理");
  }

  ngOnInit(): void {
    this.initForm();
    this.irisService.fetchProducts({}, (resp) => {
      this.irisProducts = this.convertIrisProduct(resp);
      this.irisProductsAll = this.irisProducts;
      this.salesResultHeader = this.salesResultHeader.sort();
    });
  }

  initForm() {
    this.form = this.builer.group({
      keyword: [this.keyword, []],
      showOldProducts: [this.showOldProducts, []],
    });
  }

  clear(table: Table) {
    table.clear();
  }

  doSearch() {
    this.showOldProducts = this.form.value.showOldProducts;
    const keyword = this.form.value.keyword;
    if (!keyword) {
      this.irisProducts = this.irisProductsAll;
      return;
    }
    const keywords = keyword.split("\n").map((p) => p.trim());
    this.irisProducts = this.irisProductsAll?.filter((p) => {
      const filteredAsins = p.asins.filter((asin) => keywords.includes(asin));
      return keywords.includes(p.jan) || filteredAsins.length > 0;
    });
  }

  doSearchFive() {
    this.irisProducts = this.irisProductsAll?.filter((p) => {
      const percentage = p.lvonDiffPrice / p.lvonPrice;
      console.log(p.lvonDiffPrice, p.lvonPrice, percentage);
      return percentage > 0.05;
    });
  }

  doShowAll() {
    this.irisProducts = this.irisProductsAll;
  }

  canSearch(): boolean {
    return true;
  }

  getProfitStyle(profit: number): string {
    if (profit === -1) {
      return "color: black;";
    }
    if (profit < 0) {
      return "color: red";
    }
    return "color: blue";
  }

  getViewFee(fee: number): string {
    if (fee === -1) {
      return "0";
    }
    return "-" + fee.toLocaleString();
  }

  downloadCsv(table) {
    const values = table.filteredValue ?? this.irisProducts;
    const resultsHeader = this.salesResultHeader.join(",");
    const csv =
      "商品名,型番,カラー,商品コード,JAN,ASIN,入数,原価(税抜),原価(税込),利益率,発注レベル,大型,総サイズ,W(mm),D(mm),H(mm),重量,更新日," +
      resultsHeader +
      "\n" +
      values
        .map((p) => {
          const orderLevel = this.orderLevels.find(
            (l) => l.value === p.orderLevel
          );
          const getOrEmpty = (n: number) => (n > 0 ? n : "");
          const w = getOrEmpty(p.sizeW);
          const d = getOrEmpty(p.sizeD);
          const h = getOrEmpty(p.sizeH);

          let total = this.getTotalSize(p);
          const totalSize = total > 0 ? total : "";

          const salesResults = this.salesResultHeader.map((month) => {
            const found = p.salesResults.find(
              (r: IrisSalesResult) => r.salesMonth == month
            );
            return found?.salesQuantity ?? 0;
          });

          return [
            p.productName?.replace(/\r?\n/g, ""),
            p.modelNumber?.replace(/\r?\n/g, ""),
            p.color,
            p.productCode,
            p.jan,
            p.asins.join("/"),
            p.quantity,
            p.lvonPrice,
            p.lvonPriceInTax,
            Math.round(p.profitRate * 1000) / 10,
            orderLevel?.name,
            p.isBigType ? "○" : "x",
            totalSize,
            w,
            d,
            h,
            getOrEmpty(p.kilogram),
            p.lastImportedAt,
            ...salesResults,
          ].join(",");
        })
        .join("\n");

    this.outputCsv(csv, "iris.csv");
  }

  isOverSize160(p: Iris): boolean {
    const total = this.getTotalSize(p);
    return total > 1600; // mmなので1600
  }

  getLvonDiffPrice(ip: Iris) {
    if (!ip.latestLvonPrice) return false;

    const diff = ip.lvonPrice - ip.latestLvonPrice;
    if (diff === 0) return false;

    if (diff > 0) {
      return "+" + diff;
    }
    return diff;
  }

  openOrderLevelSetting(op, ev, iris: Iris) {
    op.toggle(ev);
    this.selectedIris = iris;
  }

  updateOrderLevel(op: any, level: string, iris: Iris) {
    const id = iris?.id ?? this.selectedIris.id;
    const body = {
      id,
      orderLevel: level as IrisOrderLevel,
    };
    this.irisService.updateOrderLevel(body, (resp) => {
      if (iris) {
        iris.orderLevel = level;
      } else {
        this.selectedIris.orderLevel = level;
      }
      this.lastOrderLevel = level;
      op?.hide();
    });
  }

  updateMemo(iris: Iris) {
    const id = iris?.id ?? this.selectedIris.id;
    const body = {
      id,
      memo: iris.memo,
    };
    this.irisService.updateMemo(body, (resp) => { });
  }

  getOrderLevel(orderLevel: string): string {
    if (!!!orderLevel) {
      return "";
    }
    const ent = this.orderLevels.find(
      (ol) => ol.value == (orderLevel as IrisOrderLevel)
    );
    return ent.name;
  }

  hasLastOrderLevel(): boolean {
    return !!this.lastOrderLevel;
  }

  getTotalSize(p: Iris): number {
    const getOrEmpty = (n: number) => (n > 0 ? n : "");
    const w = getOrEmpty(p.sizeW);
    const d = getOrEmpty(p.sizeD);
    const h = getOrEmpty(p.sizeH);

    let total = 0;
    if (w !== "" && d !== "" && h !== "") {
      total = w + d + h;
    }
    return total;
  }

  getRowStyle(isOldProduct: boolean) {
    if (isOldProduct) {
      return "unpublic";
    }
    return "";
  }

  canShowRecord(iris: Iris): boolean {
    if (this.showOldProducts) {
      return true;
    }

    return !iris.isOldProduct;
  }

  setUseFba(e, iris: Iris) {
    const useFba = e.checked;
    this.irisService.updateFba({ id: iris.id, useFba }, (resp) => { });
  }

  // -------------------------------- private

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector("#csv-donwload") as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }

  private convertIrisProduct(pq: IrisProductQuery): Iris[] {
    return pq.irisProduct.map((ip) => {
      const p = ip.product;
      const aps = ip.product.amazonProducts;
      let amazonPrice = -1;
      if (aps && aps.length > 0) {
        const prices = aps.map((a) => a.latestPrice).filter((a) => a > 0);
        if (prices.length > 0) {
          amazonPrice = Math.min(...prices);
        }
      }

      const mainAp = aps && aps.find((ap) => ap.isMain);
      const ap = mainAp ? mainAp : aps && aps[0];

      const isBigType = this.bigTypeDeliveryPrices.includes(
        ap?.fbaShippingFee ?? 0
      );

      let fee = ap && ap.categoryFee + ap.salesCommission;
      if (!isBigType && ip.useFba && ap) {
        fee += ap.fbaShippingFee;
      }
      let firstAsin = ap && ap.asin;
      if (!fee) {
        fee = -1;
      }

      const lvonPriceInTax = Math.floor(ip.lvonPrice * 1.1);

      const latestLvonPrice = ip.latestHistory?.lvonPrice;
      const latestLvonPriceInTax = latestLvonPrice
        ? Math.floor(ip.lvonPrice * 1.1)
        : undefined;

      let profitPrice = -1;
      if (amazonPrice !== -1 && fee !== -1) {
        profitPrice = amazonPrice - fee - lvonPriceInTax;
      }

      let profitRate = 0;
      if (profitPrice !== -1) {
        profitRate = profitPrice / amazonPrice;
      }

      const asins = mainAp ? [mainAp.asin] : aps.map((ap) => ap.asin);
      const asinsHtml = asins
        .map((asin) => {
          return (
            '<a target="_blank" href="https://www.amazon.co.jp/dp/' +
            asin +
            '">' +
            asin +
            "</a>"
          );
        })
        .join(", ");

      let orderLevel = ip.orderLevel;
      if (!orderLevel) {
        orderLevel = "-";
      }

      const now = new Date();
      const before1Month = now.setMonth(now.getMonth() - 1);
      const before1Month1Day = new Date(before1Month).setDate(1);
      const isOldProduct =
        before1Month1Day > new Date(ip.lastImportedAt).getTime();

      const salesResults = ip.irisSalesResults?.map((r) => {
        this.salesResultHeader.push(r.salesMonth);
        return {
          salesMonth: r.salesMonth,
          salesQuantity: r.salesQuantity,
        };
      });
      // distinct
      this.salesResultHeader = Array.from(new Set(this.salesResultHeader));

      let diffPrice = 0;
      if (latestLvonPrice) {
        diffPrice = ip.lvonPrice - latestLvonPrice;
      }

      const iris: Iris = {
        id: ip.id,
        productCode: ip.irisProductCode,
        productName: ip.name,
        jan: p.jan,
        asins,
        asinsHtml,
        firstAsin,
        modelNumber: ip.modelNumber,
        quantity: ip.quantity,
        discontinuedSchedule: ip.discontinuedSchedule,
        note: ip.note,
        priceRevision: ip.priceRevision,
        lvonPrice: ip.lvonPrice,
        lvonPriceInTax,
        latestLvonPrice,
        latestLvonPriceInTax,
        lvonDiffPrice: diffPrice,
        amazonPrice,
        profitPrice,
        profitRate,
        sizeW: ip.outerCaseSizeW,
        sizeH: ip.outerCaseSizeH,
        sizeD: ip.outerCaseSizeD,
        kilogram: ip.kilogram,
        fee,
        orderLevel,
        color: ip.color,
        memo: ip.memo,
        isOldProduct,
        salesResults,
        isBigType,
        createdAt: ip.createdAt,
        updatedAt: ip.updatedAt,
        lastImportedAt: ip.lastImportedAt,
        useFba: ip.useFba,
      };
      return iris;
    });
  }
}

interface Iris {
  id: number;
  productCode: string;
  productName: string;
  jan: string;
  asins: string[];
  asinsHtml: string;
  firstAsin: string;
  modelNumber: string;
  quantity: number;
  discontinuedSchedule: string;
  note: string;
  priceRevision: string;
  amazonPrice: number;
  lvonPrice: number;
  lvonPriceInTax: number;
  latestLvonPrice: number;
  latestLvonPriceInTax: number;
  lvonDiffPrice: number;
  profitPrice: number;
  profitRate: number;
  sizeW: number;
  sizeH: number;
  sizeD: number;
  kilogram: number;
  fee: number;
  orderLevel: string;
  isOldProduct: boolean; // 2ヶ月以上前に更新された商品の場合は古い商品とみなす
  salesResults: IrisSalesResult[];
  color: string;
  memo: string;
  isBigType: boolean;
  createdAt: string;
  updatedAt: string;
  lastImportedAt: string;
  useFba: boolean;
}

interface IrisSalesResult {
  salesMonth: string;
  salesQuantity: number;
}
