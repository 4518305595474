import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  /**
   * ログイン必須ページの場合、ログインしていなかったら強制的にログインページに遷移する
   */
  public loginRequired() {
    if (!this.userService.isLogined()) {
      this.router.navigate(['/login']);
    }
  }

  /**
   * 未ログイン必須ページの場合、ログインしていたら強制的にトップページに遷移する
   */
  public notLoginRequired() {
    if (this.userService.isLogined()) {
      this.router.navigate(['/']);
    }
  }

  public goLoginPage() {
    this.router.navigate(['/login']);
  }

  public goHomePage() {
    this.router.navigate(['/']);
  }
}
