<div *ngFor="let stock of stocks">
  <p-tag severity="warning" value="{{ stock.area }}"></p-tag>
  <p-tag severity="success" value="{{ stock.prefecture }}"></p-tag>
  <p-tag severity="primary" value="{{ getStoreName(stock.storeName) }}"></p-tag>
  <p-tag
    [style]="getStyle(stock.hasStock)"
    severity="danger"
    value="{{ stock.stockMark }} {{ stock.note }}"
  ></p-tag>
</div>
