<div class="grid login">
  <div class="col-4"></div>
  <div class="col-4">
    <h1 class="center">ようこそ</h1>
    <p-panel header="ログイン" class="center">
      <form [formGroup]="form" #formDir="ngForm">
        <div class="formgrid grid">
          <div class="field col-12" *ngIf="loginFail">
            <p-message
              severity="error"
              text="メールアドレスまたはパスワードが間違っています"
            ></p-message>
          </div>
          <div class="field col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
              <input
                type="text"
                class="ng-dirty ng-invalid"
                formControlName="email"
                pInputText
                placeholder="メールアドレス"
              />
            </div>
            <p-message
              severity="error"
              *ngIf="isEmailRequired()"
              text="メールアドレスは必須です"
            ></p-message>
            <p-message
              severity="error"
              *ngIf="isEmailInvalid()"
              text="メールアドレスの形式が間違っています"
            ></p-message>
          </div>

          <div class="field col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
              <input
                type="password"
                formControlName="password"
                pPassword
                placeholder="ログインパスワード"
              />
            </div>
            <p-message
              severity="error"
              *ngIf="isPasswordRequired()"
              text="パスワードは必須です"
            ></p-message>
            <p-message
              severity="error"
              *ngIf="isPasswordLength()"
              text="パスワードは8文字以上です"
            ></p-message>
          </div>

          <div class="field col-12">
            <p-checkbox
              [formControlName]="'rememberMe'"
              binary="true"
              label="ログインを記憶する"
            ></p-checkbox>
          </div>

          <div class="field col-12 btn-full">
            <p-button
              label="ログイン"
              class="p-button-info"
              (click)="doLogin()"
              [disabled]="form.invalid"
            ></p-button>
          </div>
        </div>
      </form>
    </p-panel>
  </div>
  <div class="col-4"></div>
</div>
