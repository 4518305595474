import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DateService } from 'src/app/services/date.service';
import { MessageService } from 'src/app/services/message.service';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { UserService } from 'src/app/services/user.service';
import { UserAmazonNoticeProductsGQL } from 'src/gql/generated';

@Component({
  selector: 'app-amazon-nofication',
  templateUrl: './amazon-notification.component.html',
  styleUrls: ['./amazon-notification.component.sass']
})
export class AmazonNoficationComponent {
  products = [];
  allProducts = [];
  dateOpts = [
    { label: "全て", value: "all" },
  ];
  filterDate = "all";
  // keyが下の方にもあるので注意
  noticeOpts = [
    { label: "全て", value: "all" },
    { value: "is_minus_40", label: "40%以上減" },
    { value: "is_plus_30", label: "30%以上増" },
    { value: "is_minus_seller", label: "出品者減" },
    { value: "is_zero_seller", label: "出品者0" },
    { value: "is_return_seller", label: "出品者復活" },
    { value: "is_return_amazon", label: "Amazon復活" },
    { value: "is_zero_amazon", label: "Amazon0" },
    { value: "is_minus_40_amazon", label: "Amazon40%以上減" },
    { value: "is_plus_30_amazon", label: "Amazon30%以上増" },
  ];
  filterNotice = ["all"];
  csvHeader = ["ASIN",
    "JAN",
    "商品名",
    "Amazon価格",
    "ランキング",
    "出品者数",
    "価格減少（40%以上）",
    "価格上昇（30%以上）",
    "出品者復活",
    "出品者なし",
    "出品者減少（50%以上/3人以上）",
    "Amazon本体切れ",
    "Amazon本体復活",
    "Amazon本体価格急落",
    "Amazon本体価格急騰",
  ];

  constructor(
    private elementRef: ElementRef,
    private routingService: RoutingService,
    private subtitleService: SubtitleService,
    private userService: UserService,
    private gql: UserAmazonNoticeProductsGQL,
  ) {
    this.routingService.loginRequired();
    this.userService.amazonNotifyRequired();
    this.subtitleService.setSubTitle("Amazon通知");
  }

  async ngOnInit() {
    await this.loadAmazonNotification();
    this.buildSelectOptions();
  }

  async loadAmazonNotification() {
    const resp = await firstValueFrom(this.gql.fetch());
    this.products = resp.data?.userAmazonNoticeProducts || [];
    this.allProducts = [...this.products];
  }

  buildSelectOptions() {
    let filteredDate = [];

    this.products.forEach((p) => {
      p.amazonNotifications.forEach((n) => {
        if (!filteredDate.includes(n.notifiedAt)) {
          filteredDate.push(n.notifiedAt);
        }
      });
    });
    const sorted = filteredDate.sort((a, b) => {
      return a > b ? -1 : 1;
    });
    sorted.forEach((d) => {
      this.dateOpts.push({
        label: d,
        value: d,
      });
    });
  }

  onChangeFilterSelect(e: any) {
    const isDateAll = this.filterDate === "all";
    const isNoticeAll = this.filterNotice.includes("all");
    if (isDateAll && isNoticeAll) {
      this.products = [...this.allProducts];
      return;
    }

    this.products = this.allProducts.filter((p) => {
      return p.amazonNotifications.some((n) => {
        const filterByDate = isDateAll || n.notifiedAt === this.filterDate;
        const filterByNotice = isNoticeAll || this.filterNotice.includes(n.notificationType);
        return filterByDate && filterByNotice;
      });
    });
    this.products.forEach((p) => {
      p.amazonNotifications = p.amazonNotifications?.filter((n) => {
        const filterByDate = isDateAll || n.notifiedAt === this.filterDate;
        const filterByNotice = isNoticeAll || this.filterNotice.includes(n.notificationType);
        return filterByDate && filterByNotice;
      });
    });
  }

  /**
   * 
   "ASIN",
    "商品名",
    "過去価格",
    "現在価格",
    "過去出品者数",
    "現在出品者数",
    "価格減少（40%以上）",
    "価格上昇（30%以上）",
    "出品者復活",
    "出品者なし",
    "出品者減少（50%以上/3人以上）",
    "Amazon本体切れ",
    "Amazon本体復活",
    "Amazon本体価格急落",
    "Amazon本体価格急騰",
  ];
    { value: "is_minus_40", label: "40%以上減" },
    { value: "is_plus_30", label: "30%以上増" },
    { value: "is_minus_seller", label: "出品者減" },
    { value: "is_zero_seller", label: "出品者0" },
    { value: "is_return_seller", label: "出品者復活" },
    { value: "is_return_amazon", label: "Amazon復活" },
    { value: "is_zero_amazon", label: "Amazon0" },
    { value: "is_minus_40_amazon", label: "Amazon40%以上減" },
    { value: "is_plus_30_amazon", label: "Amazon30%以上増" },

   */
  downloadCsv(table) {
    const values = this.products.length > 0 ? this.products : this.allProducts;
    const resultsHeader = this.csvHeader.join("\t");
    const csv =
      resultsHeader +
      "\n" +
      values
        .map((tmp) => {
          const ap = tmp.amazonProduct;
          const ans = tmp.amazonNotifications;
          const isTarget = (type) => ans.find((n) => n.notificationType === type) ? "o" : "";
          return [
            ap.asin,
            tmp.product.jan,
            ap.name,
            ap.latestPrice,
            ap.latestRanking,
            ap.latestSellerCount,
            isTarget("is_minus_40"),
            isTarget("is_plus_30"),
            isTarget("is_minus_seller"),
            isTarget("is_zero_seller"),
            isTarget("is_return_seller"),
            isTarget("is_return_amazon"),
            isTarget("is_zero_amazon"),
            isTarget("is_minus_40_amazon"),
            isTarget("is_plus_30_amazon"),
          ].join("\t");
        })
        .join("\n");

    this.outputCsv(csv, "amazon_nofitication.tsv");
  }

  // -------------------------------- private

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector("#csv-donwload") as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }
}