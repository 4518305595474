import { ApolloModule, APOLLO_OPTIONS, Apollo } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache, ApolloLink, split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { NgModule } from "@angular/core";

import { environment } from "src/environments/environment";

import { UserService } from "../services/user.service";

import { ErrorCode } from "../models/error-code.enum";
import { Router } from "@angular/router";
import { MessageService } from "../services/message.service";
import { HttpService } from "../services/http.service";

// error handring: https://stackoverflow.com/questions/49420667/angular-apollo-error-handling
@NgModule({
  exports: [ApolloModule],
})
export class GraphQLModule {
  constructor(
    private router: Router,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private httpService: HttpService,
    private userService: UserService,
    private messageService: MessageService
  ) {
    const uri = environment.api.gql_url;
    const http = this.httpLink.create({ uri });

    const basic = setContext((operation, context) => ({
      headers: {
        Accept: "charset=utf-8",
      },
    }));

    const auth = setContext(async (operation, context) => {
      const errCode = await this.userService.refresh(operation.operationName);
      if (errCode) {
        switch (errCode) {
          case ErrorCode.AuthenticateError:
            this.userService.forceLogout(ErrorCode.AuthenticateError, (err) => {
              setTimeout(() => {
                this.messageService.setFlashMessage(
                  "warn",
                  "警告",
                  "認証情報の有効期限が切れました。ログアウトします。お手数ですが、再ログインをお願いいたします。"
                );
                this.router.navigate(["/login"]);
              }, 200);
            });
            return {};
          default:
            this.httpService.handleError(errCode);
            return {};
        }
      }
      const token = userService.getUserToken();
      if (token) {
        return {
          headers: {
            "x-app-authorization": token,
          },
        };
      }
      return {};
    });

    this.apollo.create({
      link: ApolloLink.from([basic, auth, http]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: "all",
          fetchPolicy: "network-only",
        },
        query: {
          errorPolicy: "all",
          fetchPolicy: "network-only",
        },
        mutate: {
          errorPolicy: "all",
        },
      },
    });
  }
}
