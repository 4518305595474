import { Injectable } from "@angular/core";
import {
  IrisProductGQL,
  IrisProductQuery,
  IrisProductQueryVariables,
  UpdateIrisFbaGQL,
  UpdateIrisFbaMutation,
  UpdateIrisFbaMutationVariables,
  UpdateIrisMemoGQL,
  UpdateIrisMemoMutation,
  UpdateIrisMemoMutationVariables,
  UpdateOrderLevelGQL,
  UpdateOrderLevelMutation,
  UpdateOrderLevelMutationVariables,
} from "src/gql/generated";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class IrisService {
  constructor(
    private http: HttpService,
    private irisProductGQL: IrisProductGQL,
    private updateOrderLevelGQL: UpdateOrderLevelGQL,
    private updateMemoGQL: UpdateIrisMemoGQL,
    private updateIrisFbaGQL: UpdateIrisFbaGQL
  ) {}

  public async fetchProducts(
    body: IrisProductQueryVariables,
    success: (resp: IrisProductQuery) => void,
    error?: any
  ) {
    const resp = await this.irisProductGQL
      .fetch(body, { fetchPolicy: "network-only" })
      .toPromise();

    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async updateOrderLevel(
    body: UpdateOrderLevelMutationVariables,
    success: (resp: UpdateOrderLevelMutation) => void,
    error?: any
  ) {
    const resp = await this.updateOrderLevelGQL.mutate(body).toPromise();

    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async updateMemo(
    body: UpdateIrisMemoMutationVariables,
    success: (resp: UpdateIrisMemoMutation) => void,
    error?: any
  ) {
    const resp = await this.updateMemoGQL.mutate(body).toPromise();

    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async updateFba(
    body: UpdateIrisFbaMutationVariables,
    success: (resp: UpdateIrisFbaMutation) => void,
    error?: any
  ) {
    const resp = await this.updateIrisFbaGQL.mutate(body).toPromise();

    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }
}
