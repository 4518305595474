<div class="grid">
  <div class="col-4"></div>
  <div class="col-4">
    <h1 class="center">ようこそ</h1>
    <div class="center">{{ user.email }} さん</div>
    <p-panel header="新規会員登録">
      <form [formGroup]="form" #formDir="ngForm">
        <div class="field grid">
          <div class="col-12" *ngIf="checkFail">
            <p-message severity="error" text="認証コードが間違っているかすでに登録されています"></p-message>
          </div>

          <div class="col-12" style="margin-top: 5px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
              <input type="text" class="ng-dirty ng-invalid" formControlName="authCode" pInputText
                placeholder="メールに届いた4桁の認証コード" />
            </div>
            <p-message severity="error" *ngIf="isAuthCodeRequired()" text="認証コードは必須です"></p-message>
          </div>

          <div class="col-12" style="margin-top: 12px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
              <input type="password" class="ng-dirty ng-invalid" formControlName="password" pPassword
                placeholder="登録するパスワード" />
            </div>
            <p-message severity="error" *ngIf="isPasswordRequired()" text="パスワードは必須です"></p-message>
            <p-message severity="error" *ngIf="isPasswordLength()" text="パスワードは8文字以上です"></p-message>
          </div>

          <div class="col-12" style="margin-top: 12px">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
              <input type="password" class="ng-dirty ng-invalid" formControlName="passwordCheck" pPassword
                placeholder="登録するパスワード(確認用)" />
            </div>
            <p-message severity="error" *ngIf="isPasswordCheckRequired()" text="パスワードは必須です"></p-message>
            <p-message severity="error" *ngIf="isPasswordCheckLength()" text="パスワードは8文字以上です"></p-message>
            <p-message severity="error" *ngIf="!isSamePassword()" text="パスワードが一致しません"></p-message>
          </div>

          <div class="col-12 center btn-full" style="margin-top: 12px">
            <p-button label="らくじゃんに登録する" styleClass="p-button-info" (click)="doRegist()"
              [disabled]="formDir.form.invalid || !isSamePassword()">
            </p-button>
          </div>
        </div>
      </form>
    </p-panel>
  </div>
  <div class="col-4"></div>
</div>