import { Injectable } from "@angular/core";
import {
  DeleteUserPricetarProductsGQL,
  DeleteUserPricetarProductsMutationVariables,
  RegisterUserPricetarProductsGQL,
  RegisterUserPricetarProductsMutationVariables,
  UpdateUserPricetarProductGQL,
  UpdateUserPricetarProductMutationVariables,
  UserPricetarProductsGQL,
  UserPricetarProductsQuery,
  UserPricetarProductsQueryVariables,
} from "src/gql/generated";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class UserPricetarService {
  constructor(
    private http: HttpService,
    private userPricetarProductsGQL: UserPricetarProductsGQL,
    private registerGQL: RegisterUserPricetarProductsGQL,
    private deleteGQL: DeleteUserPricetarProductsGQL,
    private updateStoreGQL: UpdateUserPricetarProductGQL
  ) {}

  public async list(
    body: UserPricetarProductsQueryVariables,
    success: (resp: UserPricetarProductsQuery) => void,
    error?: any
  ) {
    const resp = await this.userPricetarProductsGQL.fetch(body, {}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async register(
    body: RegisterUserPricetarProductsMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.registerGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.registerUserPricetarProducts);
    }
  }

  public async delete(
    body: DeleteUserPricetarProductsMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.deleteGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.deleteUserPricetarProducts);
    }
  }

  public async updateSettings(
    body: UpdateUserPricetarProductMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.updateStoreGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.updateUserPricetarProducts);
    }
  }
}
