import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserSedoristService } from "src/app/services/user-sedorist.service";
import { UserPricetarService } from "src/app/services/user-pricetar.service";
import {
  Condition,
  UserPricetarProductInput,
  UserSedoristProductInput,
} from "src/gql/generated";
import { DateService } from "src/app/services/date.service";
import { MessageService } from "src/app/services/message.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.sass"],
})
export class RegisterComponent implements OnInit {
  pricetar: string;
  sedorist: string;

  isSedoristSearchList = "false";

  pricetars: Array<Priceter>;
  sedorists: Array<Sedorist>;

  constructor(
    private router: Router,
    private message: MessageService,
    private routingService: RoutingService,
    private userService: UserService,
    private dateService: DateService,
    private sedoristService: UserSedoristService,
    private pricetarService: UserPricetarService
  ) {
    this.routingService.loginRequired();
    this.userService.shareRequired();
  }

  ngOnInit(): void { }

  goBack() {
    this.router.navigate(["/share"]);
  }

  doPricetarCheck() {
    if (!this.pricetar) {
      return;
    }
    this.sedorists = [];
    this.pricetars = this.pricetar
      .split("\n")
      .map((row) => {
        const data = row.split("\t");
        if (data[0] === "注文日") {
          return;
        }
        return this.convertPricetar(data);
      })
      .filter((p) => p !== undefined && p.asin !== undefined);
  }

  doSedoristCheck() {
    if (!this.sedorist) {
      return;
    }
    this.pricetars = [];
    this.sedorists = this.sedorist
      .split("\n")
      .map((row) => {
        const data = row.split("\t");
        if (data[0] === "SKU") {
          return;
        }
        const p = this.convertSedorist(data);
        return p;
      })
      .filter((p) => p !== undefined && p.asin !== undefined);
  }

  doUploadPriceter() {
    if (!this.pricetars || this.pricetars.length < 1) {
      return;
    }
    const inputs: Array<UserPricetarProductInput> = [];
    this.pricetars.forEach((pricetar) => {
      let condition = Condition.New;
      if (pricetar.condition === "old") {
        condition = Condition.Old;
      }
      inputs.push({
        asin: pricetar.asin,
        sku: pricetar.sku,
        productName: pricetar.name,
        buyingPrice: pricetar.buyingPrice,
        sellingPrice: pricetar.sellingPrice,
        salesCommission: pricetar.costPrice,
        condition,
        orderDate: this.dateService.toStringDate(pricetar.orderDate),
        deliveryRoute: pricetar.deliveryRoute,
        isPublic: true,
      });
    });
    this.pricetarService.register({ userPricetarInputs: inputs }, (resp) => {
      this.message.setFlashMessageWithClear(
        "success",
        "成功",
        "プライスター情報の登録に成功しました"
      );
    });
    this.pricetar = "";
    this.pricetars = [];
  }

  doUploadSedorist() {
    if (!this.sedorists || this.sedorists.length < 1) {
      return;
    }
    const inputs: Array<UserSedoristProductInput> = [];
    this.sedorists.forEach((sedorist) => {
      let condition = Condition.New;
      if (sedorist.condition === "old") {
        condition = Condition.Old;
      }
      const jan = sedorist.jan.replace(/\r?\n? /g, "");
      inputs.push({
        asin: sedorist.asin,
        jan,
        sku: sedorist.sku,
        productName: sedorist.name,
        buyingPrice: sedorist.buyingPrice,
        sellingPrice: sedorist.sellingPrice,
        condition,
        deliveryRoute: sedorist.deliveryRoute,
        buyingDate: this.dateService.toStringDate(sedorist.buyingDate),
        isPublic: true,
      });
    });
    this.sedoristService.register({ userSedoristInputs: inputs }, (resp) => {
      this.message.setFlashMessageWithClear(
        "success",
        "成功",
        "セドリスト情報の登録に成功しました"
      );
    });
    this.sedorist = "";
    this.sedorists = [];
  }

  hasPriceter() {
    return this.pricetars && this.pricetars.length > 0;
  }

  hasSedorist() {
    return this.sedorists && this.sedorists.length > 0;
  }

  private convertPricetar(row: Array<string>): Priceter {
    const soldNum = Number(row[16]);
    return {
      asin: row[3],
      sku: row[4],
      name: row[1],
      buyingPrice: Math.ceil(Number(row[9]) / soldNum),
      sellingPrice: Math.ceil(Number(row[6]) / soldNum),
      costPrice: Math.ceil(Number(row[11]) / soldNum),
      condition: row[15],
      deliveryRoute: row[5],
      orderDate: new Date(row[0]),
    };
  }

  private convertSedorist(row: Array<string>): Sedorist {
    let jan = "";
    if (row[1] && row[1].match("[0-9]{13}")) {
      jan = row[1];
    }

    const asin = row[2];
    let sku = row[0];
    if (!!!sku) {
      sku = asin;
    }

    if (this.isSedoristSearchList === "true") {
      return this.convertSedoristSearch(row, asin, jan, sku);
    }

    const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());
    let buyingDate = new Date(row[20]);
    if (isInvalidDate(buyingDate)) {
      buyingDate = new Date();
    }
    return {
      asin,
      jan,
      sku,
      name: row[3],
      referencePrice: Number(row[9]),
      sellingPrice: Number(row[16]),
      buyingPrice: Number(row[14]),
      deliveryRoute: row[18],
      condition: row[17],
      buyingDate,
    };
  }

  private convertSedoristSearch(
    row: Array<string>,
    asin: string,
    jan: string,
    sku: string
  ): Sedorist {
    const referencePrice = row[9] === "-" ? undefined : Number(row[9]);
    return {
      asin,
      jan,
      sku,
      name: row[3],
      referencePrice,
      sellingPrice: Number(row[4]),
      buyingPrice: Number(row[14]),
      deliveryRoute: row[7],
      condition: row[22],
      buyingDate: new Date(row[8]),
    };
  }
}

export interface Priceter {
  asin: string;
  sku: string;
  name: string;
  sellingPrice: number;
  buyingPrice: number;
  costPrice: number;
  deliveryRoute: string;
  condition: string;
  orderDate: Date;
}

export interface Sedorist {
  asin: string;
  jan: string;
  sku: string;
  name: string;
  referencePrice: number;
  sellingPrice: number;
  buyingPrice: number;
  deliveryRoute: string;
  condition: string;
  buyingDate: Date;
}
