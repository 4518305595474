import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { UserService } from "src/app/services/user.service";
import { SubtitleService } from "src/app/services/subtitle.service";
import { MessageService } from "src/app/services/message.service";
import { RoutingService } from "src/app/services/routing.service";
import { ErrorCode } from "src/app/models/error-code.enum";

@Component({
  selector: "app-check",
  templateUrl: "./check.component.html",
  styleUrls: ["./check.component.sass"],
})
export class CheckComponent implements OnInit {
  @Input() user = {
    authCode: null,
    email: "",
    password: "",
    passwordCheck: "",
  };
  checkFail = false; // 登録失敗
  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpService,
    private userService: UserService,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private message: MessageService
  ) {
    this.routingService.notLoginRequired();
    this.subtitleService.setSubTitle("登録完了");
  }

  ngOnInit() {
    this.initForm();
    const token = this.activatedRoute.snapshot.paramMap.get("token");
    this.userService.registerCheck(
      token,
      (resp) => {
        this.user.email = resp.data.registerTokenValidate.email;
      },
      (err) => {
        switch (err.code) {
          case ErrorCode.UnAuthorizeRequest:
            this.message.setFlashMessageWithClear(
              "warn",
              "警告",
              "登録情報が見つかりませんでした。お手数ですが、再度ご登録をお願いします。"
            );
            this.routingService.goLoginPage();
            break;
          default:
            this.http.handleError(err);
            this.routingService.goLoginPage();
            break;
        }
      }
    );
  }

  initForm() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl({ value: this.user.email, disabled: true }, []),
      authCode: new UntypedFormControl(this.user.authCode, [Validators.required]),
      password: new UntypedFormControl(
        this.user.password,
        Validators.compose([Validators.required, Validators.minLength(8)])
      ),
      passwordCheck: new UntypedFormControl(this.user.passwordCheck, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  isAuthCodeRequired(): boolean {
    const code = this.form.get("authCode");
    return code.touched && code.errors && code.errors.required;
  }

  isSellerIdRequired(): boolean {
    const code = this.form.get("mwsSellerId");
    return code.touched && code.errors && code.errors.required;
  }

  isAuthTokenRequired(): boolean {
    const code = this.form.get("mwsAuthToken");
    return code.touched && code.errors && code.errors.required;
  }

  isPasswordLength(): boolean {
    const pass = this.form.get("password");
    return pass.touched && pass.errors && pass.errors.minlength;
  }

  isPasswordRequired(): boolean {
    const pass = this.form.get("password");
    return pass.touched && pass.errors && pass.errors.required;
  }

  isPasswordCheckLength(): boolean {
    const pass = this.form.get("passwordCheck");
    return pass.touched && pass.errors && pass.errors.minlength;
  }

  isPasswordCheckRequired(): boolean {
    const pass = this.form.get("passwordCheck");
    return pass.touched && pass.errors && pass.errors.required;
  }

  isSamePassword(): boolean {
    const pass = this.form.get("password");
    const validPass = pass.touched && !pass.errors;
    if (!validPass) {
      return true;
    }
    const passCheck = this.form.get("passwordCheck");
    const validPassCheck = passCheck.touched && !passCheck.errors;
    if (!validPassCheck) {
      return true;
    }
    return this.form.value.password === this.form.value.passwordCheck;
  }

  doRegist() {
    if (this.form.invalid || !this.isSamePassword()) {
      return;
    }
    const v = this.form.value;
    const token = this.activatedRoute.snapshot.paramMap.get("token");
    this.userService.register(
      v.password,
      v.authCode,
      token,
      (resp) => {
        this.userService.loginProcess(this.user.email, resp.token, false);
        this.routingService.goHomePage();
        this.message.setFlashMessageWithClear(
          "success",
          "成功",
          "会員登録が完了しました。引き続きらくじゃんをお楽しみください。"
        );
      },
      (err) => {
        switch (err.code) {
          case ErrorCode.AlreadyRegisteredEmailError:
            this.message.setFlashMessage(
              "warn",
              "警告",
              "すでに登録済みです。ご登録したメールアドレスでログインしてください。"
            );
            this.routingService.goLoginPage();
            break;
          case ErrorCode.MWSKeyError:
            this.message.setFlashMessage(
              "error",
              "エラー",
              "MWS出品者ID または MWS認証トークンが正しくありません。もう一度ご入力をお願いします。"
            );
            break;
          case ErrorCode.NotMatchSaltKey:
            this.message.setFlashMessage(
              "error",
              "エラー",
              "認証コードが一致しません。もう一度ご確認の上、正しい認証コードの入力をお願いします。"
            );
            break;
          default:
            this.http.handleError(err);
            this.routingService.goLoginPage();
            break;
        }
      }
    );
  }
}
