import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss']
})
export class FlashMessageComponent implements OnInit {
  messages: Array<any>;

  constructor(private messageService: MessageService) { }

  ngOnInit() {
    this.messageService.flashState.subscribe(message => {
      if (message) {
        this.messages = [message];
      } else {
        this.messages = [];
      }
    });
  }
}
