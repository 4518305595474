import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import {
  ProductDetailsGQL,
  ProductDetailsQueryVariables,
  ProductDetailsQuery,
} from "src/gql/generated";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(
    private http: HttpService,
    private productDetailGQL: ProductDetailsGQL
  ) {}

  public async fetchProducts(
    body: ProductDetailsQueryVariables,
    success: (resp: ProductDetailsQuery) => void,
    error?: any
  ) {
    const resp = await this.productDetailGQL
      .fetch(body, { fetchPolicy: "network-only" })
      .toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }
}
