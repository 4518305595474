<a id="csv-donwload"></a>

<p-panel header="検索" [toggleable]="true" [collapsed]="false">
    <div class="grid">
        <div class="col-3">
            <label for="ms-groupedArea">通知日での絞り込み</label>
            <div class="card justify-content-center p-label">
                <p-dropdown [options]="dateOpts" [(ngModel)]="filterDate" (onChange)="onChangeFilterSelect($event)"
                    defaultLabel="絞り込みの日付を選択" display="chip">
                </p-dropdown>
            </div>
        </div>
        <div class="col-3">
            <label for="ms-groupedArea">通知項目での絞り込み</label>
            <div class="card justify-content-center p-label">
                <p-multiSelect [options]="noticeOpts" [(ngModel)]="filterNotice"
                    (onChange)="onChangeFilterSelect($event)" defaultLabel="絞り込みの通知項目を選択" display="chip">
                </p-multiSelect>
            </div>
        </div>
    </div>
</p-panel>
<div class="table-header-sticky-outer">
    全{{ products.length.toLocaleString() }}件
    <p-table #mainTable [value]="products" columnResizeMode="expand" styleClass="p-datatable-responsive"
        tableStyleClass="p-datatable-responsive" class="table-header-sticky" [paginator]="true" [rows]="100"
        [rowsPerPageOptions]="[100, 250, 500]" [showCurrentPageReport]="true"
        currentPageReportTemplate="{totalRecords} 件中 {first} 〜 {last}" paginatorPosition="both"
        [globalFilterFields]="['product.jan', 'amazonProduct.name', 'amazonProduct.asin']">
        <ng-template pTemplate="caption">
            <div class="table-caption">
                <div class="grid">
                    <div class="col-3">
                        <div class="p-d-flex">
                            <button type="button" pButton pRipple icon="pi pi-download" (click)="downloadCsv(mainTable)"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        </div>
                    </div>
                    <div class="col-5"></div>
                    <div class="col-3">
                        <span class="p-input-icon-left ml-auto input-full">
                            <i class="pi pi-search"></i>
                            <input pInputText class="input-full" type="text"
                                (input)="mainTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="検索キーワード" />
                        </span>
                    </div>
                    <div class="col-1">
                        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                            (click)="mainTable.clear()"></button>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th width="4%">画像</th>
                <th width="10%">Keepaグラフ</th>
                <th width="6%" pSortableColumn="asin">
                    ASIN<p-sortIcon field="asin"></p-sortIcon>
                </th>
                <th width="6%">JAN</th>
                <th width="20%">商品名</th>
                <th width="4%" pSortableColumn="topCategory">
                    カテゴリ<p-sortIcon field="buyingPrice"></p-sortIcon>
                </th>
                <th width="15%">通知内容</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>
                    <a href="https://keepa.com/#!product/5-{{ product.amazonProduct.asin }}" target="_blank">
                        <img src="{{ product.amazonProduct.imageUrl }}" style="width: 50px" />
                    </a>
                </td>
                <td>
                    <a href="https://keepa.com/#!product/5-{{ product.amazonProduct.asin }}" target="_blank">
                        <img style="max-width: 100%" src="https://graph.keepa.com/pricehistory.png?asin={{
                product.amazonProduct.asin
              }}&domain=co.jp&width=500&height=250&salesrank=1" />
                    </a>
                </td>
                <td>
                    <a href="https://www.amazon.co.jp/dp/{{ product.amazonProduct.asin }}" target="_blank">
                        {{ product.amazonProduct.asin }}
                    </a>
                </td>
                <td>{{ product.product.jan }}</td>
                <td>
                    {{ product.amazonProduct.name }}
                </td>
                <td>{{ product.amazonProduct.topCategory }}</td>
                <td><app-badges [notices]="product.amazonNotifications"></app-badges></td>
            </tr>
        </ng-template>
    </p-table>
</div>