import { Injectable } from "@angular/core";
import {
  BuyingStoresGQL,
  BuyingStoresQuery,
  BuyingStoreTypesGQL,
  BuyingStoreTypesQuery,
  DeleteBuyingStoreGQL,
  DeleteBuyingStoreMutationVariables,
  DeleteBuyingStoreTypeGQL,
  DeleteBuyingStoreTypeMutationVariables,
  RegisterBuyingStoreGQL,
  RegisterBuyingStoreMutationVariables,
  RegisterBuyingStoreTypeGQL,
  RegisterBuyingStoreTypeMutationVariables,
  UpdateBuyingStoreOrderGQL,
  UpdateBuyingStoreOrderMutationVariables,
  UpdateBuyingStoreTypeOrderGQL,
  UpdateBuyingStoreTypeOrderMutationVariables,
} from "src/gql/generated";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class BuyingStoreService {
  constructor(
    private http: HttpService,
    private buyingStoresGQL: BuyingStoresGQL,
    private buyingStoreTypesGQL: BuyingStoreTypesGQL,
    private registerGQL: RegisterBuyingStoreGQL,
    private registerTypeGQL: RegisterBuyingStoreTypeGQL,
    private deleteGQL: DeleteBuyingStoreGQL,
    private deleteTypeGQL: DeleteBuyingStoreTypeGQL,
    private updateOrderGQL: UpdateBuyingStoreOrderGQL,
    private updateTypeOrderGQL: UpdateBuyingStoreTypeOrderGQL,
  ) {}

  public async list(success: (resp: BuyingStoresQuery) => void, error?: any) {
    const resp = await this.buyingStoresGQL.fetch({}, {}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async register(
    body: RegisterBuyingStoreMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.registerGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.registerBuyingStore);
    }
  }

  // typesの取得
  public async fetchTypes(
    success: (resp: BuyingStoreTypesQuery) => void,
    error?: any
  ) {
    const resp = await this.buyingStoreTypesGQL.fetch({}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async registerType(
    body: RegisterBuyingStoreTypeMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.registerTypeGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.registerBuyingStoreType);
    }
  }

  public async delete(
    body: DeleteBuyingStoreMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.deleteGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.deleteBuyingStore);
    }
  }

  public async deleteType(
    body: DeleteBuyingStoreTypeMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.deleteTypeGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.deleteBuyingStoreType);
    }
  }

  public async updateBuyingStoreOrder(
    body: UpdateBuyingStoreOrderMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.updateOrderGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.updateBuyingStoreOrder);
    }
  }

  public async updateBuyingStoreTypeOrder(
    body: UpdateBuyingStoreTypeOrderMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.updateTypeOrderGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.updateBuyingStoreTypeOrder);
    }
  }
}
