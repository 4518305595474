<div class="p-grid">
  <div class="p-lg-4 p-md-12 p-sm-12">
    <p-button
      label="Amazon価格追跡商品の追加"
      styleClass="p-button-info"
      (click)="doSearch()"
      *ngIf="isRegisteredTelegram"
    ></p-button>
  </div>
  <div class="p-lg-7 p-md-12 p-sm-12"></div>
  <div class="p-lg-1 p-md-12 p-sm-12" style="text-align: right">
    <i
      class="pi pi-cog clickable"
      style="font-size: 2rem"
      (click)="toggleSettingDialog(true)"
    ></i>
  </div>
</div>

<p-dialog
  header="Telegram設定"
  [(visible)]="canShowSetting"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="p-grid">
    <div class="p-lg-12 p-md-12 p-sm-12">
      <p class="m-0">
        以下の「Telegram連携」ボタンを押してTelegram連携を行います。<br />
      </p>
    </div>
    <div class="p-lg-6 p-md-12 p-sm-12">
      <p-tag
        styleClass="mr-2"
        icon="pi pi-check"
        severity="warning"
        value="連携済み: {{ telegramName }}"
        [rounded]="true"
        *ngIf="isRegisteredTelegram"
      ></p-tag>
    </div>
    <div class="p-lg-6 p-md-12 p-sm-12" style="text-align: right">
      <a
        pButton
        pRipple
        href="https://telegram.me/rakujan_bot?start={{ telegramHashKey }}"
        target="_blank"
        styleClass="p-button-success"
        label="Telegram連携"
      ></a>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="toggleSettingDialog(false)"
      label="閉じる"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>

<p-panel header="検索" [toggleable]="true" [collapsed]="false">
  <form [formGroup]="form" #formDir="ngForm">
    <div class="p-grid">
      <div class="p-lg-4 p-md-12 p-sm-12">
        <div class="p-inputgroup full-height">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input
            type="text"
            class="input-full"
            formControlName="searchText"
            pInputText
            placeholder="検索ワード(ASIN, JAN, 商品名...)"
          />
        </div>
      </div>

      <div class="p-lg-6">
        <p-button
          label="検索"
          styleClass="p-button-info"
          (click)="doSearch()"
          [disabled]="form.invalid"
        ></p-button>
      </div>
    </div>
  </form>
</p-panel>

<p-table [value]="products">
  <ng-template pTemplate="header">
    <tr>
      <th>画像</th>
      <th>ASIN</th>
      <th>商品名</th>
      <th>販売開始日</th>
      <th>ランキング</th>
      <th>出品者数</th>
      <th>Amazon価格</th>
      <th>閾値価格</th>
      <th>通知状態</th>
      <th>削除</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      <td>{{ product.icon_url }}</td>
      <td>{{ product.asin }}</td>
      <td>
        <a>
          {{ product.product_name }}
        </a>
      </td>
      <td>{{ product.sales_date }}</td>
      <td>{{ product.amazon_ranking }}</td>
      <td>{{ product.amazon_seller_num }}</td>
      <td>{{ product.amazon_price1 }}</td>
      <td>{{ product.threshold_price }}</td>
      <td>link</td>
      <td><i class="pi pi-trash"></i></td>
    </tr>
  </ng-template>
</p-table>
