import { Injectable } from "@angular/core";
import {
  DeleteUserSedoristProductsGQL,
  DeleteUserSedoristProductsMutationVariables,
  RegisterUserSedoristProductsGQL,
  RegisterUserSedoristProductsMutationVariables,
  UpdateUserSedoristProductGQL,
  UpdateUserSedoristProductMutationVariables,
  UserSedoristProductsGQL,
  UserSedoristProductsQuery,
  UserSedoristProductsQueryVariables,
} from "src/gql/generated";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class UserSedoristService {
  constructor(
    private http: HttpService,
    private userSedoristProductsGQL: UserSedoristProductsGQL,
    private registerGQL: RegisterUserSedoristProductsGQL,
    private deleteGQL: DeleteUserSedoristProductsGQL,
    private updateStoreGQL: UpdateUserSedoristProductGQL
  ) {}

  public async list(
    body: UserSedoristProductsQueryVariables,
    success: (resp: UserSedoristProductsQuery) => void,
    error?: any
  ) {
    const resp = await this.userSedoristProductsGQL.fetch(body, {}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async register(
    body: RegisterUserSedoristProductsMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.registerGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.registerUserSedoristProducts);
    }
  }

  public async delete(
    body: DeleteUserSedoristProductsMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.deleteGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.deleteUserSedoristProducts);
    }
  }

  public async updateSettings(
    body: UpdateUserSedoristProductMutationVariables,
    success: any,
    error?: any
  ) {
    const resp = await this.updateStoreGQL.mutate(body).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.updateUserSedoristProducts);
    }
  }
}
