import { Component, Input, OnInit } from "@angular/core";
import { BicStock } from "src/app/models/biccamera-product";

@Component({
  selector: "app-biccamera-stock",
  templateUrl: "./biccamera-stock.component.html",
  styleUrls: ["./biccamera-stock.component.sass"],
})
export class BiccameraStockComponent implements OnInit {
  @Input() stocks: BicStock[];

  removeTargets = [
    "コジマ×ビックカメラ",
    "ビックカメラ",
    "Air BicCamera",
  ]

  constructor() { }

  ngOnInit(): void { }

  getStyle(hasStock: boolean): any {
    if (hasStock) {
      return null;
    } else {
      return {
        background: "gray",
      };
    }
  }

  // 名前から "ビックカメラ " と "Air BicCamera " を削除する
  getStoreName(name: string): string {
    this.removeTargets.forEach((target) => {
      name = name.replace(target, "");
    });
    return name.trim();
  }
}
