import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  /**
   * dateをstringにformatする
   * @param target input date
   */
  toStringDate(target: Date): string {
    return moment(target).format('YYYYMMDDHHmmss');
  }

  /**
   * csvファイルの末尾につける日付のフォーマット
   */
  getCsvDate(): string {
    return moment().format('YYYYMMDD_HHmmss');
  }

  /**
   * 加算、もしくは減算した日付を返す
   * @param days 計算日付
   */
  getAddedDateTrancated(days: number): Date {
    return moment().startOf('day').add(days, 'days').toDate();
  }
}
