import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { ErrorCode } from 'src/app/models/error-code.enum';
import { HttpService } from 'src/app/services/http.service';
import { MessageService } from 'src/app/services/message.service';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() user = { email: '', password: '' };
  @Input() rememberMe: boolean;
  apiInvalidEmail = false; // api結果、invalid emailになった場合
  loginFail = false; // ログイン失敗
  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private http: HttpService,
    private userService: UserService,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private message: MessageService,
  ) {
    this.routingService.notLoginRequired();
    this.subtitleService.setSubTitle('ログイン');
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.user.email, [Validators.required, Validators.email]),
      password: new UntypedFormControl(this.user.password, [Validators.required, Validators.minLength(8)]),
      rememberMe: new UntypedFormControl(this.rememberMe, []),
    });
  }

  doLogin() {
    if (this.form.invalid) {
      return;
    }
    // init
    this.apiInvalidEmail = false;
    this.loginFail = false;

    const v = this.form.value;
    const user = new User();
    user.email = v.email;
    this.userService.login(v.email, v.password, v.rememberMe, resp => {
      this.router.navigate(['/']);
      this.message.setFlashMessageWithClear('success', '成功', 'ログインしました');
    }, err => {
      console.log(err);
      switch (err.code) {
        case ErrorCode.ValidationError:
          this.apiInvalidEmail = err.errors.filter(e => e.field === 'email').length === 1;
          break;
        case ErrorCode.InvalidEmailOrPassword:
          this.loginFail = true;
          break;
        default:
          this.http.handleError();
          break;
      }
    });
  }

  isEmailInvalid(): boolean {
    const email = this.form.get('email');
    return email.touched && email.errors && email.errors.email || this.apiInvalidEmail;
  }

  isEmailRequired(): boolean {
    const email = this.form.get('email');
    return email.touched && email.errors && email.errors.required;
  }

  isPasswordLength(): boolean {
    const pass = this.form.get('password');
    return pass.touched && pass.errors && pass.errors.minLength;
  }

  isPasswordRequired(): boolean {
    const pass = this.form.get('password');
    return pass.touched && pass.errors && pass.errors.required;
  }
}
