import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { SubtitleService } from "src/app/services/subtitle.service";
import { TrackProduct } from "src/app/models/track-product";
import { UserTelegram } from "src/gql/generated";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-price-tracker",
  templateUrl: "./price-tracker.component.html",
  styleUrls: ["./price-tracker.component.scss"],
})
export class PriceTrackerComponent implements OnInit {
  searchText: string;
  order = "updated_at_desc";
  userTelegram: UserTelegram;
  telegramHashKey: string;
  isRegisteredTelegram: boolean;
  telegramName: string;
  canShowSetting: boolean;
  form: UntypedFormGroup;

  products: Array<TrackProduct> = [];

  constructor(
    private subtitleService: SubtitleService,
    private userService: UserService
  ) {
    subtitleService.setSubTitle("追跡商品一覧");
  }

  ngOnInit() {
    this.canShowSetting = false;
    this.isRegisteredTelegram = false;
    this.loadTelegram();
    this.initForm();
  }

  async loadTelegram() {
    await this.userService.getUserTelegram((resp) => {
      this.telegramHashKey = resp.me.telegramHashKey;
      this.userTelegram = resp.me.userTelegram;
      if (this.userTelegram && this.userTelegram.chatID) {
        this.isRegisteredTelegram = true;
        this.telegramName =
          this.userTelegram.lastName + " " + this.userTelegram.firstName;
      }
    });
  }

  initForm() {
    this.form = new UntypedFormGroup({
      searchText: new UntypedFormControl(this.searchText, []),
    });
  }

  doSearch() {
    console.log(this.form.value);
  }

  toggleSettingDialog(isShow: boolean) {
    this.canShowSetting = isShow;
  }
}
