<p-panel header="検索" [toggleable]="true" [collapsed]="false">
  <form [formGroup]="form" #f="ngForm">
    <div class="grid">
      <div class="col-3">
        <p-dropdown [options]="shops" formControlName="shopType" optionLabel="label">
        </p-dropdown>
      </div>
      <div class="col-3">
        <div class="p-inputgroup full-height">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input type="text" class="input-full" formControlName="shopCode" pInputText placeholder="ショップIDを入力（必須）"
            [disabled]="isDoingCrawl()" />
        </div>
      </div>

      <div class="col-6">
        <p-message severity="error" *ngIf="isShopCodeRequired()" text="ショップIDは必須です"></p-message>
        <p-button label="検索" icon="pi pi-search" (onClick)="doSearch()"
          [disabled]="form.invalid || isDoingCrawl()"></p-button>
      </div>

      <div class="col-12">
        <p-panel header="詳細検索" [toggleable]="false" [collapsed]="false">
          <h4>価格絞り込み</h4>
          <br />
          <div class="grid">
            <div class="col-3">
              <span class="p-float-label">
                <input type="number" id="minPrice" formControlName="minPrice" pInputText />
                <label for="minPrice">最小価格</label>
              </span>
            </div>
            <div class="col-3">
              <span class="p-float-label">
                <input type="number" id="maxPrice" formControlName="maxPrice" pInputText />
                <label for="maxPrice">最大価格</label>
              </span>
            </div>
            <div class="col-3">
              <span class="p-float-label">
                <input type="number" id="minGross" formControlName="minGross" pInputText />
                <label for="minPrice">最小の粗利</label>
              </span>
            </div>
            <div class="col-3">
              <span class="p-float-label">
                <input type="number" id="minProfitRate" formControlName="minProfitRate" pInputText />
                <label for="minPrice">最小の利益率(1~100)</label>
              </span>
            </div>
          </div>

          <h4>Amazon絞り込み</h4>
          <br />
          <div class="grid">
            <div class="col-6">
              <span class="p-float-label">
                <input type="number" id="minRanking" formControlName="minRanking" pInputText />
                <label for="minPrice">最小Amazonランキング</label>
              </span>
            </div>
            <div class="col-6">
              <p-dropdown [options]="hasNewProducts" formControlName="hasNewProduct" optionLabel="label"
                [showClear]="true" placeholder="Amazon新品出品者">
              </p-dropdown>
            </div>
          </div>
        </p-panel>
      </div>

      <div class="col-6">
        <p-button styleClass="p-button-danger" label="クローリング開始" icon="pi pi-search" (onClick)="confirm()"
          [disabled]="!canCrawling()">
        </p-button>
      </div>

      <div class="col-6">
        <p-button styleClass="p-button-warning" label="CSVダウンロード" icon="pi pi-search" (onClick)="doDownload()"
          [disabled]="!canDownload()">
        </p-button>
        <a id="csv-donwload"></a>
      </div>
    </div>
  </form>
</p-panel>

<div *ngIf="isDoingCrawl()">
  <p-progressBar mode="indeterminate" [style]="{ height: '2px' }"></p-progressBar>
  <p-messages [value]="crawlingMessages"></p-messages>
</div>

<div *ngIf="isShowErrorMessage()">
  <p-messages severity="warn">
    <ng-template pTemplate>
      <div style="padding-left: 10px">
        <b>他の人がクローリング中</b>
        <br />
        他の人がクローリング中です。しばらく時間をおいて実行をお願いします。<br />しばらくこのメッセージが継続して表示される場合は管理者までお問い合わせをお願いします。
      </div>
    </ng-template>
  </p-messages>
</div>

<div class="grid" *ngIf="canShowShop()">
  <div class="col-4">
    <p-card subheader="{{ targetShop.shopCode }} のショップ名" [style]="{ height: '180px' }">
      <div class="grid">
        <div class="col-8">
          <h2 *ngIf="hasLink()">
            <a href="{{ targetShop.shopUrl }}" target="_blank">{{
              targetShop.shopName
              }}</a>
          </h2>
          <h2 *ngIf="!hasLink()">
            {{ targetShop.shopName }}
          </h2>
        </div>
        <div class="col-4">
          <b>前回実行時</b>
          <div>最小価格: {{ targetShop.latestMinPrice | number }} 円</div>
          <div>最大価格: {{ targetShop.latestMaxPrice | number }} 円</div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="col-2">
    <p-card subheader="最終更新 開始 日時" [style]="{ height: '180px' }">
      <h2>{{ getLatestStarted() }}</h2>
    </p-card>
  </div>
  <div class="col-2">
    <p-card subheader="最終更新 完了 日時" [style]="{ height: '180px' }">
      <h2>{{ getLatestEnded() }}</h2>
    </p-card>
  </div>
  <div class="col-2">
    <p-card subheader="登録済み商品数" [style]="{ height: '180px' }">
      <div>
        ショップ:
        <b>
          <font size="5">{{ targetShop.totalProductNum }}</font>
        </b>
      </div>
      <div>
        アマゾン:
        <b>
          <font size="5">{{ targetShop.totalAmazonNum }}</font>
        </b>
      </div>
    </p-card>
  </div>
  <div class="col-2">
    <p-card subheader="更新商品数" [style]="{ height: '180px' }">
      <div>
        ショップ:
        <b>
          <font size="5">{{ targetShop.updatedProductNum }}</font>
        </b>
      </div>
      <div>
        アマゾン:
        <b>
          <font size="5">{{ targetShop.updatedAmazonNum }}</font>
        </b>
      </div>
    </p-card>
  </div>
</div>

<p-dialog header="最終確認" [(visible)]="isDisplayModal" [modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <p>スクレイピングを開始してもよろしいでしょうか？</p>
  <p>（時間がかかる場合があります）</p>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="doCrawling()" label="クローリング実行"></button>
    <button type="button" pButton icon="pi pi-times" (click)="isDisplayModal = false" label="キャンセル"
      class="p-button-secondary"></button>
  </p-footer>
</p-dialog>