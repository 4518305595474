import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { SubtitleService } from './services/subtitle.service';
import { MessageService } from './services/message.service';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  public menus: any;
  public subtitle: string;

  constructor(
    private router: Router,
    public userService: UserService,
    public subtitleService: SubtitleService,
    private message: MessageService,
  ) { }

  ngOnInit() {
    this.initMenu();
    this.initRoute();
    this.subtitleService.subtitleState.subscribe(title => {
      this.subtitle = title;
    });
  }

  initMenu() {
    this.menus = [
      {
        label: 'Amazon通知',
        routerLink: ['/']
      }
    ];
  }

  goRegister() {
    this.router.navigate(['/register']);
  }

  goLogin() {
    this.router.navigate(['/login']);
  }

  initRoute() {
    // nothing todo
  }

  logout() {
    this.userService.logout(resp => {
      this.router.navigate(['/login']);
      this.message.setFlashMessageWithClear('success', '成功', 'ログアウトしました');
    }, err => {
      this.router.navigate(['/login']);
      this.message.setFlashMessageWithClear('error', 'エラー', 'エラーが発生したため、強制ログアウトしました。ご利用になる場合は再度ログインしてください。');
    });
  }
}
