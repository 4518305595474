import { Component, OnInit } from "@angular/core";
import { PrimeIcons } from "primeng/api";
import { RoutingService } from "src/app/services/routing.service";
import { SubtitleService } from "src/app/services/subtitle.service";

@Component({
  selector: "app-changelog",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.sass"],
})
export class ChangelogComponent implements OnInit {
  histories: History[];

  constructor(
    private routingService: RoutingService,
    private subtitleService: SubtitleService
  ) {
    this.routingService.loginRequired();
    this.subtitleService.setSubTitle("更新履歴");
  }

  ngOnInit(): void {
    this.histories = [
      {
        title: "Amazon連携機能の追加",
        contents: `Amazon連携が出来ていない方向けにAmazon連携ができるようになりました。Amazon連携されていない方はホームにAmazon連携ボタンが表示されるため、そこからAmazon連携をお願いいたします。 （ボタンが表示されない方はすでに連携済みのため、何もする必要はございません）`,
        date: "2023/08/28",
        icon: PrimeIcons.CHECK,
        color: "#607D8B",
      },
      {
        title: "寝かせ、レジ落ち、メモ設定の追加",
        contents: `商品共有で寝かせ、レジ落ち、メモ設定ができるようになりました。
        商品共有ページの商品の一番右の設定アイコンから設定できます。`,
        date: "2022/11/29",
        icon: PrimeIcons.CHECK,
        color: "#607D8B",
      },
      {
        title: "登録者の絞り込みを追加",
        contents: `商品共有で登録者の絞り込みができるようになりました`,
        date: "2022/07/16",
        icon: PrimeIcons.CHECK,
        color: "#607D8B",
      },
      {
        title: "チーム名表示を追加",
        contents: `ホームにチーム名を表示するように変更しました`,
        date: "2022/07/13",
        icon: PrimeIcons.CHECK,
        color: "#607D8B",
      },
    ];
  }
}

export interface History {
  date: string;
  title: string;
  contents: string;
  icon: string;
  color: string;
}
