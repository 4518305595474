import { Component, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { DateService } from 'src/app/services/date.service';
import { ShopType, Shop, UpdateShopProductsMutationVariables, ProductDetailsQueryVariables, ProductDetailsQuery } from 'src/gql/generated';
import { ShopService } from 'src/app/services/shop.service';
import { ErrorCode } from 'src/app/models/error-code.enum';
import { MessageService } from 'src/app/services/message.service';
import { HttpService } from 'src/app/services/http.service';
import { interval, Subscription } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/services/routing.service';
import { SelectItem } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit {

  notFoundText = '取得記録なし';

  crawlingMessages = [{ severity: 'warn', summary: '', detail: 'データ取得中...' }];
  defaultShopName = '登録なし';

  targetShop: Shop;
  // form values
  shopCode: string;
  shopType = 'rakuten';
  shops: SelectItem[] = [
    { label: '楽天', value: 'rakuten' },
  ];
  minPrice = 1000;
  maxPrice = 50000;
  minGross: number; // 最低の粗利
  minProfitRate = 10; // 最低の利益率
  hasNewProduct;
  hasNewProducts: SelectItem[] = [
    { label: '新品出品者あり', value: true },
    { label: '新品出品者なし', value: false },
  ];
  minRanking = 50000;

  isSearched = false;
  isDownloading = false;
  isDisplayModal = false;
  private watchSub: Subscription;

  form: UntypedFormGroup;

  private element: HTMLElement; // for csv download

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private userService: UserService,
    private elementRef: ElementRef,
    private subtitleService: SubtitleService,
    private builer: UntypedFormBuilder,
    private http: HttpService,
    private shopService: ShopService,
    private productService: ProductService,
    private dateService: DateService,
    private message: MessageService,
  ) {
    this.routingService.loginRequired();
    this.userService.rakutenRequired();
    this.subtitleService.setSubTitle('商品検索');
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.initTargetShop();
    this.initForm();
    this.route.queryParams.subscribe(p => {
      if (p.st && p.sc) {
        this.shopCode = p.sc;
        this.initForm();
        let shopType: ShopType;
        switch (p.st) {
          case 'rakuten':
            shopType = ShopType.Rakuten;
        }
        this.search(shopType, this.shopCode);
      }
    });
  }

  initForm() {
    this.form = this.builer.group({
      shopCode: [this.shopCode, [Validators.required]],
      shopType: [this.shopType, [Validators.required]],
      minPrice: [this.minPrice, [Validators.required]],
      maxPrice: [this.maxPrice, [Validators.required]],
      minGross: [this.minGross, []],
      minProfitRate: [this.minProfitRate, []],
      hasNewProduct: [this.hasNewProduct, []],
      minRanking: [this.minRanking, []],
    });
  }

  async initTargetShop() {
    this.targetShop = {
      shopCode: null,
      shopName: this.defaultShopName,
      shopUrl: undefined,
      totalProductNum: 0,
      updatedProductNum: 0,
      totalAmazonNum: 0,
      updatedAmazonNum: 0,
      isDoing: false,
    };
    await this.shopService.fetchUserUpdateShop(resp => {
      const isDoing = resp.shopCode !== '';
      const shopCode = isDoing ? resp.shopCode : null;
      const shopType = isDoing ? resp.shopType : this.shopType;
      this.targetShop.isDoing = isDoing;
      this.targetShop.shopCode = shopCode;
      this.shopType = shopType;
      this.doWatchShop();
    });
  }

  isShopCodeRequired(): boolean {
    const code = this.form.get('shopCode');
    return code.touched && code.errors && code.errors.required;
  }

  doSearch(ev?) {
    this.isDownloading = false;
    this.initTargetShop();
    const v = this.form.value;
    this.search(v.shopType, v.shopCode);
    this.router.navigate([], { queryParams: { st: v.shopType, sc: v.shopCode } });
  }

  private search(shopType: ShopType, shopCode: string) {
    this.shopService.fetchShop(shopType, shopCode, resp => {
      this.targetShop = resp;
      this.isSearched = true;
    }, err => {
      switch (err.code) {
        case ErrorCode.InternalServerError:
          this.message.setFlashMessageWithClear('error', 'エラー', '予期せぬエラーが発生しました。時間を置いて、再度お試しください。');
          break;
        case ErrorCode.NotFound404:
          this.message.setFlashMessageWithClear('warn', '警告', '検索対象のショップが見つかりませんでした。');
          break;
        case ErrorCode.AuthenticateError:
          this.message.setFlashMessageWithClear('warn', '警告', '登録情報が見つかりませんでした。お手数ですが、再度ご登録をお願いします。');
          this.routingService.goLoginPage();
          break;
        default:
          this.http.handleError(err);
      }
      this.isSearched = true;
    });
  }

  confirm() {
    this.isDisplayModal = true;
  }

  isShowErrorMessage() {
    const startedAt = this.targetShop.latestStartedAt;
    const endedAt = this.targetShop.latestEndAt;
    const isErrOccurrd = startedAt != null && endedAt != null && startedAt > endedAt;
    const a = !this.targetShop.isDoing && isErrOccurrd; // 実行中じゃなくてデータ更新が不整合だったらエラーと判断
    return !this.targetShop.isDoing && isErrOccurrd; // 実行中じゃなくてデータ更新が不整合だったらエラーと判断
  }

  canCrawling() {
    return this.isSearched && this.targetShop.shopUrl !== undefined && !this.isDoingCrawl() && !this.isShowErrorMessage();
  }

  isDoingCrawl() {
    return this.targetShop.isDoing;
  }

  doCrawling() {
    this.isDisplayModal = false;
    const v = this.form.value;
    const body: UpdateShopProductsMutationVariables = {
      shopType: v.shopType,
      shopCode: v.shopCode,
      minPrice: v.minPrice,
      maxPrice: v.maxPrice,
    };
    if (v.minGross) { body.minGross = v.minGross; }
    if (v.minProfitRate) { body.minProfitRate = v.minProfitRate; }
    if (v.minRanking) { body.minRanking = v.minRanking; }
    if (v.hasNewProduct) { body.hasNewProduct = v.hasNewProduct.value; }

    this.shopService.updateShopProducts(body, resp => {
      const p = resp.updateShopProducts;
      this.targetShop.latestStartedAt = p.latestStartedAt;
      this.targetShop.latestEndAt = p.latestEndAt;
      this.targetShop.totalProductNum = p.totalProductNum;
      this.targetShop.isDoing = true;
      this.doWatchShop();
    }, err => {
      switch (err.code) {
        case ErrorCode.AlreadyUpdatingProduct:
          this.message.setFlashMessageWithClear('info', '情報', '現在データの取得中です。もうしばらくお待ちください');
          break;
        default:
          this.http.handleError(err);
          break;
      }
    });
  }

  // shopの状況をwatchする
  doWatchShop() {
    if (!this.targetShop.isDoing) {
      return;
    }
    const shopType: any = this.shopType;
    const shopCode = this.targetShop.shopCode;
    this.shopType = shopType;
    this.shopCode = shopCode;
    this.initForm();
    this.search(shopType, shopCode);
    this.watchSub = interval(3000).subscribe(() => {
      this.search(shopType, shopCode);
      if (!this.targetShop.isDoing) {
        this.watchSub.unsubscribe();
      }
    });
  }

  canDownload() {
    return this.isSearched &&
      this.targetShop.totalProductNum > 0 &&
      this.targetShop.latestStartedAt !== null &&
      this.targetShop.latestEndAt !== null &&
      this.targetShop.latestStartedAt < this.targetShop.latestEndAt &&
      !this.isDownloading;
  }

  doDownload(ev?) {
    this.isDownloading = true;
    const v = this.form.value;
    const body: ProductDetailsQueryVariables = {
      shopType: v.shopType,
      shopCode: v.shopCode,
      minPrice: v.minPrice,
      maxPrice: v.maxPrice,
    };
    if (v.minGross) { body.minGross = v.minGross; }
    if (v.minProfitRate) { body.minProfitRate = v.minProfitRate; }
    if (v.minRanking) { body.minRanking = v.minRanking; }
    if (v.hasNewProduct) { body.hasNewProduct = v.hasNewProduct.value; }
    this.productService.fetchProducts(body, resp => {
      const csv = this.createAndFilterCsv(body, resp);
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = this.element.querySelector('#csv-donwload') as HTMLAnchorElement;
      link.href = url;
      link.download = body.shopCode + '_' + this.dateService.getCsvDate() + '.csv';
      link.click();
      this.isDownloading = false;
    }, err => {
      this.isDownloading = false;
      this.http.handleError(err);
    });
  }

  canShowShop() {
    return this.isSearched;
  }

  hasLink() {
    return this.targetShop.shopUrl !== undefined;
  }

  getLatestStarted() {
    if (this.targetShop.latestStartedAt === undefined || this.targetShop.latestStartedAt.length === 0) {
      return this.notFoundText;
    }
    return this.targetShop.latestStartedAt;
  }

  getLatestEnded() {
    if (this.targetShop.latestEndAt === undefined || this.targetShop.latestEndAt.length === 0) {
      return this.notFoundText;
    }
    return this.targetShop.latestEndAt;
  }

  private createAndFilterCsv(body: ProductDetailsQueryVariables, resp: ProductDetailsQuery) {
    const header = [
      'asin',
      'jan',
      '商品名',
      'Amazonランキング',
      'Amazon価格',
      'Amazon販売開始日',
      'Amazon新規出品者数',
      'FBA手数料',
      '楽天価格',
      '楽天URL',
      '粗利',
      '利益率(%)',
    ].join(',');
    const csvs: Array<string> = [];

    resp.productDetails.forEach(product => {
      let ap;
      product.amazonProducts.forEach(tmp => {
        if (ap === undefined) {
          ap = tmp;
        } else {
          if (ap.latestPrice > tmp.latestPrice) {
            ap = tmp;
          }
        }
      });

      product.rakutenProducts.forEach(rp => {
        const amazonTotalFee = ap.salesCommission + ap.categoryFee + ap.fbaShippingFee;
        const grossPrice = ap.latestPrice - rp.price - amazonTotalFee;
        if (grossPrice < 1) {
          return;
        }
        const grossRate = Math.round(grossPrice / ap.latestPrice * 100);
        if (grossRate < body.minProfitRate) {
          return;
        }

        const csv = [
          ap.asin,
          product.jan,
          ap.name,
          ap.latestRanking,
          ap.latestPrice,
          ap.salesBeginDate,
          ap.newSellerNum,
          amazonTotalFee,
          rp.price,
          rp.url,
          grossPrice,
          grossRate,
        ].join('","');

        csvs.push('"' + csv + '"');
      });
    });

    const result = [header, csvs.join('\n')].join('\n');
    return result;
  }
}
