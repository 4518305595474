import { Injectable } from "@angular/core";
import { AmazonCategoriesGQL, AmazonCategoriesQuery } from "src/gql/generated";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AmazonCategoryService {
  constructor(
    private http: HttpService,
    private amazonCategoriesGQL: AmazonCategoriesGQL
  ) {}

  public async list(
    isTop: boolean,
    success: (resp: AmazonCategoriesQuery) => void,
    error?: any
  ) {
    const resp = await this.amazonCategoriesGQL.fetch({ isTop }, {}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }
}
