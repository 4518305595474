import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.sentry.using) {
      const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({ eventId });
    } else {
      throw error;
    }
  }
}
