export const environment = {
  production: true,
  tokenRefreshMin: 1440, // tokenをrefreshするさいの期限
  api: {
    base_url: 'https://rakujan.mydns.jp',
    gql_url: 'https://rakujan.mydns.jp/query',
    ws_url: 'ws://rakujan.mydns.jp:3000/query',
  },
  sentry: {
    using: true,
    dsn: 'https://3e8f62cf8cb84dfbbdacec8e40907137@sentry.io/1460705'
  },
};
