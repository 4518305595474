<p-button label="戻る" class="nav-button" styleClass="p-button-secondary" icon="pi pi-arrow-left" iconPos="left"
  (click)="goBack()"></p-button>
<p-button label="新規商品アップロード" styleClass="p-button-success" icon="pi pi-arrow-right" iconPos="left"
  (click)="goRegister()"></p-button>
<span *ngIf="isAdmin">
  <p-button label="ストア管理" styleClass="p-button-warn" icon="pi pi-arrow-right" iconPos="left"
    (click)="goStoreManage()"></p-button>
</span>
<a id="csv-donwload"></a>

<br />
<br />
<p-panel header="検索" [toggleable]="true" [collapsed]="false">
  <form [formGroup]="form" #f="ngForm">
    <div class="grid">
      <div class="col-3">
        <div>
          <b>フリーワード検索</b>
        </div>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input type="text" formControlName="keyword" id="search-word" class="input-full"
            placeholder="検索ワード ASIN, 商品名..." pInputText />
        </div>
      </div>
      <div class="col-3">
        <div>
          <b>注文/仕入日時の絞り込み</b>
        </div>
        <p-calendar formControlName="fromDate" showTime="true" hourFormat="24" dateFormat="yy/mm/dd"
          placeholder="登録日from"></p-calendar>
        〜
        <p-calendar formControlName="toDate" showTime="true" hourFormat="24" dateFormat="yy/mm/dd"
          placeholder="登録日to"></p-calendar>
      </div>
      <div class="col-3">
        <div>
          <b>商品の絞り込み</b>
        </div>
        <p-selectButton [options]="sharedOptions" formControlName="sharedOption" optionLabel="name">
        </p-selectButton>
      </div>
      <div class="col-3">
        <div>
          <b>仕入れ店舗の絞り込み</b>
        </div>
        <p-dropdown [options]="types" formControlName="buyingStoreType" optionLabel="name" [showClear]="true"
          placeholder="カテゴリ" filter="true" (onChange)="changeType()">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="form.value.buyingStoreType">
              <div>{{ form.value.buyingStoreType.name }}</div>
            </div>
          </ng-template>
          <ng-template let-type pTemplate="item">
            <div>{{ type.name }}</div>
          </ng-template>
        </p-dropdown>

        <p-dropdown [options]="buyingStores" formControlName="buyingStore" optionLabel="name" [showClear]="true"
          placeholder="店舗" filter="true">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="form.value.buyingStore">
              <div>{{ form.value.buyingStore.name }}</div>
            </div>
          </ng-template>
          <ng-template let-buyingStore pTemplate="item">
            <div>{{ buyingStore.name }}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col-2">
        <div>
          <b>アマゾンカテゴリの絞り込み</b>
        </div>
        <p-dropdown [options]="amazonCategories" formControlName="amazonCategory" optionLabel="name" [showClear]="true"
          placeholder="カテゴリ" filter="true">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="form.value.amazonCategory">
              <div>{{ form.value.amazonCategory.name }}</div>
            </div>
          </ng-template>
          <ng-template let-category pTemplate="item">
            <div>{{ category.name }}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col-2">
        <span>
          <div>
            <b>登録者の絞り込み</b>
          </div>
          <p-multiSelect [options]="users" formControlName="user" optionLabel="name" [showClear]="true"
            placeholder="登録者" filter="true">
            <ng-template pTemplate="selectedItem">
              <div *ngIf="form.value.user">
                <div>{{ form.value.user.name }}</div>
              </div>
            </ng-template>
            <ng-template let-user pTemplate="item">
              <div>{{ user.name }}</div>
            </ng-template>
          </p-multiSelect>
        </span>
      </div>

      <div class="col-2">
        <span>
          <div>
            <b>その他の絞り込み</b>
          </div>
          <p-dropdown [options]="otherSettingOptions" formControlName="otherSetting" optionLabel="name"
            [showClear]="true" placeholder="その他絞り込み" filter="true">
            <ng-template pTemplate="selectedItem">
              <div *ngIf="form.value.otherSetting">
                <div>{{ form.value.otherSetting.name }}</div>
              </div>
            </ng-template>
            <ng-template let-setting pTemplate="item">
              <div>{{ setting.name }}</div>
            </ng-template>
          </p-dropdown>
        </span>
      </div>

      <div class="col-6"></div>

      <div class="col-2">
        <p-button styleClass="p-button-info" label="プライスター検索" icon="pi pi-search" (onClick)="doSearchPricetar()">
        </p-button>
      </div>
      <div class="col-2">
        <p-button styleClass="p-button-warning" label="セドリスト検索" icon="pi pi-search" (onClick)="doSearchSedorist()">
        </p-button>
      </div>
      <div class="col-2">
        <p-button styleClass="p-button-success" label="両方の検索" icon="pi pi-search" (onClick)="doSearchMerged()">
        </p-button>
      </div>

      <div class="col-2">
        <div class="p-field-checkbox">
          <p-checkbox inputId="hiddenCols" (onChange)="hiddenCols($event)"></p-checkbox>
          <label for="hiddenCols">特定の項目を非表示</label>
        </div>
      </div>

      <div class="col-2">
        <div class="p-field-checkbox">
          <p-checkbox inputId="hiddenUnPublic" (onChange)="hiddenUnPublic($event)"></p-checkbox>
          <label for="hiddenUnPublic">非公開項目を非表示</label>
        </div>
      </div>
    </div>
  </form>
</p-panel>

<div *ngIf="hasPricetar()" class="table-header-sticky-outer">
  全{{ pricetars.length.toLocaleString() }}件
  <p-table #tpricetar [value]="pricetars" columnResizeMode="expand" styleClass="p-datatable-responsive"
    tableStyleClass="p-datatable-responsive" class="table-header-sticky">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputPricetarCsv()" class="p-mr-2"
          pTooltip="CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputAllPricetarCsv()"
          class="p-mr-2 p-button-success" pTooltip="CSV" tooltipPosition="bottom" *ngIf="isAdmin">
          全
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th width="6%">画像</th>
        <th width="10%">Keepaグラフ</th>
        <th width="6%" pSortableColumn="asin">
          ASIN<p-sortIcon field="asin"></p-sortIcon>
        </th>
        <th width="6%">JAN</th>
        <th>商品名</th>
        <th width="4%" pSortableColumn="topCategory">
          カテゴリ<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th width="4%" pSortableColumn="buyingPrice">
          仕入値<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th width="4%" pSortableColumn="sellingPrice">
          売価<p-sortIcon field="sellingPrice"></p-sortIcon>
        </th>
        <th width="4%" pSortableColumn="profit">
          利益額<p-sortIcon field="profit"></p-sortIcon>
        </th>
        <th width="4%" pSortableColumn="profitRate">
          利益率<p-sortIcon field="profitRate"></p-sortIcon>
        </th>
        <th width="10%" pSortableColumn="sku" *ngIf="showColsVal">
          SKU<p-sortIcon field="sku"></p-sortIcon>
        </th>
        <th width="8%" pSortableColumn="orderDate" *ngIf="showColsVal">
          注文日<p-sortIcon field="orderDate"></p-sortIcon>
        </th>
        <th width="6%" *ngIf="showColsVal">その他</th>
        <th width="8%" pSortableColumn="buyingStore">
          仕入店舗<p-sortIcon field="buyingStore"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="name" *ngIf="showColsVal">
          登録者/設定<p-sortIcon field="name"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-pricetar>
      <tr class="{{ getRowStyle(pricetar.isPublic) }}">
        <td>
          <a href="https://www.amazon.co.jp/dp/{{ pricetar.asin }}" target="_blank">
            <img src="{{ pricetar.imageUrl }}" style="width: 50px" />
          </a>
        </td>
        <td>
          <a href="https://keepa.com/#!product/5-{{ pricetar.asin }}" target="_blank">
            <img style="max-width: 100%" src="https://graph.keepa.com/pricehistory.png?asin={{
                pricetar.asin
              }}&domain=co.jp&width=500&height=250&salesrank=1" />
          </a>
        </td>
        <td>{{ pricetar.asin }}</td>
        <td>{{ pricetar.jan }}</td>
        <td>
          {{ pricetar.productName }}
        </td>
        <td>{{ pricetar.topCategory }}</td>
        <td>
          {{ pricetar.buyingPrice && pricetar.buyingPrice.toLocaleString() }}
        </td>
        <td>{{ pricetar.sellingPrice.toLocaleString() }}</td>
        <td>
          <span style="{{ getProfitStyle(pricetar.profit) }}">
            {{ pricetar.profit.toLocaleString() }}
          </span>
        </td>
        <td>
          <span style="{{ getProfitStyle(pricetar.profit) }}">
            {{ pricetar.profitRate | percent : "1.1" }}
          </span>
        </td>
        <td *ngIf="showColsVal">{{ pricetar.sku }}</td>
        <td *ngIf="showColsVal">{{ pricetar.orderDate | japanDate }}</td>
        <td *ngIf="showColsVal">
          <p-badge [value]="pricetar.deliveryRoute"></p-badge>
          <p-badge *ngIf="pricetar.memo" pTooltip="{{ pricetar.memo }}" tooltipPosition="left" value="メモ"
            severity="warning"></p-badge>
          <p-badge *ngIf="pricetar.ipMemo" pTooltip="{{ pricetar.ipMemo }}" tooltipPosition="left" value="知財・真贋"
            severity="info"></p-badge>
          <p-badge *ngIf="pricetar.isPriceDownInCacheRegister" value="レジ落ち" severity="success"></p-badge>
          <p-badge *ngIf="pricetar.isSleeped" value="寝かせ" severity="danger"></p-badge>
        </td>
        <td>
          <div *ngIf="!pricetar.isEditing">
            {{ pricetar.buyingStore }}
            <span *ngIf="canEdit(pricetar.email)">
              <i class="pi pi-cog clickable" (click)="openIdModal(opPricetar, $event, pricetar.id)"></i>
              <div *ngIf="hasLastStore()">
                <button pButton type="button" label="same" class="p-button-sm p-button-rounded" (click)="
                    updateSamePricetarStore(lastBuyingStore, pricetar.id)
                  "></button>
              </div>
            </span>
          </div>
          <div *ngIf="pricetar.isEditing"></div>
        </td>
        <td *ngIf="showColsVal">
          <span>{{ pricetar.name }}</span>
          <div *ngIf="canSetting(pricetar.email)">
            <div style="color: red">
              <i class="pi pi-trash clickable" (click)="doDeletePricetar(pricetar.id)"></i>
            </div>
            <i class="pi pi-cog clickable" (click)="openPricetarModal(pricetar)"></i>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="hasSedorist()">
  全{{ sedorists.length.toLocaleString() }}件
  <!-- ページサイズを選択できるようにしたい場合は解放
  <div class="p-lg-2 p-md-12 p-sm-12">
    <p-selectButton
      [options]="pageSizes"
      [(ngModel)]="selectedPageSize"
      optionLabel="size"
      class="p-button-sm"
    ></p-selectButton>
  </div>
  -->

  <p-table [value]="sedorists" [paginator]="true" [rows]="selectedPageSize.size" columnResizeMode="expand"
    styleClass="p-datatable-responsive" tableStyleClass="p-datatable-responsive" class="table-header-sticky">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputSedoristCsv()" class="p-mr-2"
          pTooltip="CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputAllSedoristCsv()"
          class="p-mr-2 p-button-success" pTooltip="CSV" tooltipPosition="bottom" *ngIf="isAdmin">
          全
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th width="7%">画像</th>
        <th width="10%">Keepaグラフ</th>
        <th width="7%" pSortableColumn="asin">
          ASIN<p-sortIcon field="asin"></p-sortIcon>
        </th>
        <th width="7%">JAN</th>
        <th>商品名</th>
        <th width="5%" pSortableColumn="topCategory">
          カテゴリ<p-sortIcon field="topCategory"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="buyingPrice">
          仕入値<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="sellingPrice">
          出品予定価格<p-sortIcon field="sellingPrice"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="profit">
          利益額<p-sortIcon field="profit"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="profitRate">
          利益率<p-sortIcon field="profitRate"></p-sortIcon>
        </th>
        <th width="4%">状態</th>
        <th width="5%" pSortableColumn="sku" *ngIf="showColsVal">
          SKU<p-sortIcon field="buyingDate"></p-sortIcon>
        </th>
        <th width="6%" *ngIf="showColsVal">その他</th>
        <th width="7%" pSortableColumn="buyingDate" *ngIf="showColsVal">
          仕入日<p-sortIcon field="buyingDate"></p-sortIcon>
        </th>
        <th width="8%" pSortableColumn="buyingStore">
          仕入店舗<p-sortIcon field="buyingStore"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="name" *ngIf="showColsVal">
          登録者/設定<p-sortIcon field="buyingStore"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sedorist>
      <tr class="{{ getRowStyle(sedorist.isPublic) }}">
        <td>
          <a href="https://www.amazon.co.jp/dp/{{ sedorist.asin }}" target="_blank">
            <img src="{{ sedorist.imageUrl }}" style="width: 50px" />
          </a>
        </td>
        <td>
          <a href="https://keepa.com/#!product/5-{{ sedorist.asin }}" target="_blank">
            <img style="max-width: 100%" src="https://graph.keepa.com/pricehistory.png?asin={{
                sedorist.asin
              }}&domain=co.jp&width=500&height=250&salesrank=1" />
          </a>
        </td>
        <td>{{ sedorist.asin }}</td>
        <td>{{ sedorist.jan }}</td>
        <td>{{ sedorist.productName }}</td>
        <td>{{ sedorist.topCategory }}</td>
        <td>{{ sedorist.buyingPrice.toLocaleString() }}</td>
        <td>{{ sedorist.sellingPrice.toLocaleString() }}</td>
        <td>
          <span style="{{ getProfitStyle(sedorist.profit) }}">
            {{ sedorist.profit.toLocaleString() }}
          </span>
        </td>
        <td>
          <span style="{{ getProfitStyle(sedorist.profit) }}">
            {{ sedorist.profitRate | percent : "1.1" }}
          </span>
        </td>
        <td>{{ sedorist.condition }}</td>
        <td *ngIf="showColsVal">{{ sedorist.sku }}</td>
        <td *ngIf="showColsVal">
          <p-badge [value]="sedorist.deliveryRoute"></p-badge>
          <p-badge *ngIf="sedorist.memo" pTooltip="{{ sedorist.memo }}" tooltipPosition="left" value="メモ"
            severity="warning"></p-badge>
          <p-badge *ngIf="sedorist.ipMemo" pTooltip="{{ sedorist.ipMemo }}" tooltipPosition="left" value="知財・真贋"
            severity="info"></p-badge>
          <p-badge *ngIf="sedorist.isPriceDownInCacheRegister" value="レジ落ち" severity="success"></p-badge>
          <p-badge *ngIf="sedorist.isSleeped" value="寝かせ" severity="danger"></p-badge>
        </td>
        <td *ngIf="showColsVal">{{ sedorist.buyingDate | japanDate }}</td>
        <td>
          <div *ngIf="!sedorist.isEditing">
            {{ sedorist.buyingStore }}
            <span *ngIf="canEdit(sedorist.email)">
              <i class="pi pi-cog clickable" (click)="openIdModal(opSedorist, $event, sedorist.id)"></i>
              <div *ngIf="hasLastStore()">
                <button pButton type="button" label="same" class="p-button-sm p-button-rounded" (click)="
                    updateSameSedoristStore(lastBuyingStore, sedorist.id)
                  "></button>
              </div>
            </span>
          </div>
          <div *ngIf="sedorist.isEditing"></div>
        </td>
        <td *ngIf="showColsVal">
          <span>{{ sedorist.name }}</span>
          <div *ngIf="canSetting(sedorist.email)">
            <div style="color: red">
              <i class="pi pi-trash clickable" (click)="doDeleteSedorist(sedorist.id)"></i>
            </div>
            <i class="pi pi-cog clickable" (click)="openSedoristModal(sedorist)"></i>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="hasMerged()">
  全{{ mergedList.length.toLocaleString() }}件
  <p-table [value]="mergedList" [paginator]="true" [rows]="selectedPageSize.size" columnResizeMode="expand"
    styleClass="p-datatable-responsive" tableStyleClass="p-datatable-responsive" class="table-header-sticky">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputMergedCsv()" class="p-mr-2"
          pTooltip="CSV" tooltipPosition="bottom"></button>
        <button type="button" pButton pRipple icon="pi pi-download" (click)="outputAllMergedCsv()"
          class="p-mr-2 p-button-success" pTooltip="CSV" tooltipPosition="bottom" *ngIf="isAdmin">
          全
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th width="6%">画像</th>
        <th width="10%">Keepaグラフ</th>
        <th width="8%" pSortableColumn="asin">
          ASIN<p-sortIcon field="asin"></p-sortIcon>
        </th>
        <th width="8%">JAN</th>
        <th>商品名</th>
        <th width="5%" pSortableColumn="topCategory">
          カテゴリ<p-sortIcon field="topCategory"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="buyingPrice">
          仕入値<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="sellingPrice">
          出品予定価格<p-sortIcon field="sellingPrice"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="profit">
          利益額<p-sortIcon field="profit"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="profitRate">
          利益率<p-sortIcon field="profitRate"></p-sortIcon>
        </th>
        <th width="4%">状態</th>
        <th width="5%" pSortableColumn="sku" *ngIf="showColsVal">
          SKU<p-sortIcon field="sku"></p-sortIcon>
        </th>
        <th width="6%" *ngIf="showColsVal">その他</th>
        <th width="7%" pSortableColumn="buyingDate" *ngIf="showColsVal">
          仕入日<br />注文日<p-sortIcon field="buyingDate"></p-sortIcon>
        </th>
        <th width="8%" pSortableColumn="buyingStore">
          仕入店舗<p-sortIcon field="buyingStore"></p-sortIcon>
        </th>
        <th width="5%" pSortableColumn="name" *ngIf="showColsVal">
          登録者/設定<p-sortIcon field="name"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-merged>
      <tr class="{{ getRowStyle(merged.isPublic) }}">
        <td>
          <a href="https://www.amazon.co.jp/dp/{{ merged.asin }}" target="_blank">
            <img src="{{ merged.imageUrl }}" style="width: 50px" />
          </a>
        </td>
        <td>
          <a href="https://keepa.com/#!product/5-{{ merged.asin }}" target="_blank">
            <img style="max-width: 100%" src="https://graph.keepa.com/pricehistory.png?asin={{
                merged.asin
              }}&domain=co.jp&width=500&height=250&salesrank=1" />
          </a>
        </td>
        <td>{{ merged.asin }}</td>
        <td>{{ merged.jan }}</td>
        <td>{{ merged.productName }}</td>
        <td>{{ merged.topCategory }}</td>
        <td>{{ merged.buyingPrice.toLocaleString() }}</td>
        <td>{{ merged.sellingPrice.toLocaleString() }}</td>
        <td>
          <span style="{{ getProfitStyle(merged.profit) }}">
            {{ merged.profit.toLocaleString() }}
          </span>
        </td>
        <td>
          <span style="{{ getProfitStyle(merged.profit) }}">
            {{ merged.profitRate | percent : "1.1" }}
          </span>
        </td>
        <td>{{ merged.condition }}</td>
        <td *ngIf="showColsVal">{{ merged.sku }}</td>
        <td *ngIf="showColsVal">
          <p-badge [value]="merged.deliveryRoute"></p-badge>
          <p-badge *ngIf="merged.memo" pTooltip="{{ merged.memo }}" tooltipPosition="left" value="メモ"
            severity="warning"></p-badge>
          <p-badge *ngIf="merged.ipMemo" pTooltip="{{ merged.ipMemo }}" tooltipPosition="left" value="知財・真贋"
            severity="info"></p-badge>
          <p-badge *ngIf="merged.isPriceDownInCacheRegister" value="レジ落ち" severity="success"></p-badge>
          <p-badge *ngIf="merged.isSleeped" value="寝かせ" severity="danger"></p-badge>
        </td>
        <td *ngIf="showColsVal">{{ merged.buyingDate | japanDate }}</td>
        <td>
          {{ merged.buyingStore }}
          <span *ngIf="canEdit(merged.email)">
            <i class="pi pi-cog clickable" (click)="openStoreMerged(opMerged, $event, merged)"></i>
            <div *ngIf="hasLastStore()">
              <button pButton type="button" label="same" class="p-button-sm p-button-rounded"
                (click)="updateSameMergedStore(lastBuyingStore, merged)"></button>
            </div>
          </span>
        </td>
        <td *ngIf="showColsVal">
          <span>{{ merged.name }}({{ merged.serviceName }})</span>
          <div *ngIf="canSetting(merged.email)">
            <div style="color: red">
              <i class="pi pi-trash clickable" (click)="doDeleteMerged(merged)"></i>
            </div>
            <i class="pi pi-cog clickable" (click)="openMergedModal(merged)"></i>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-overlayPanel #opSedorist>
  <ng-template pTemplate>
    <h5>登録済みの店舗登録</h5>
    <p-dropdown [options]="buyingStores" [(ngModel)]="selectedBuyingStore" optionLabel="name" [showClear]="true"
      placeholder="仕入れ店舗" filter="true" (onChange)="updateSedoristStore(opSedorist)">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedBuyingStore">
          <div>{{ selectedBuyingStore.name }}</div>
        </div>
      </ng-template>
      <ng-template let-buyingStore pTemplate="item">
        <div class="country-item">
          <div>{{ buyingStore.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #opPricetar>
  <ng-template pTemplate>
    <p-dropdown [options]="buyingStores" [(ngModel)]="selectedBuyingStore" optionLabel="name" [showClear]="true"
      placeholder="仕入れ店舗" filter="true" (onChange)="updatePricetarStore(opPricetar)">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedBuyingStore">
          <div>{{ selectedBuyingStore.name }}</div>
        </div>
      </ng-template>
      <ng-template let-buyingStore pTemplate="item">
        <div class="country-item">
          <div>{{ buyingStore.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #opMerged>
  <ng-template pTemplate>
    <p-dropdown [options]="buyingStores" [(ngModel)]="selectedBuyingStore" optionLabel="name" [showClear]="true"
      placeholder="仕入れ店舗" filter="true" (onChange)="updateMergedStore(opMerged)">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedBuyingStore">
          <div>{{ selectedBuyingStore.name }}</div>
        </div>
      </ng-template>
      <ng-template let-buyingStore pTemplate="item">
        <div class="country-item">
          <div>{{ buyingStore.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-template>
</p-overlayPanel>

<p-dialog header="商品詳細設定" [(visible)]="showPricetarModal" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-8">公開設定</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsPublic($event)" [(ngModel)]="settingPublic"></p-inputSwitch>
      </div>

      <div class="col-8">寝かせ</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsSleeped($event)" [(ngModel)]="settingIsSleeped"></p-inputSwitch>
      </div>

      <div class="col-8">レジ落ち</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsPriceDownInCacheRegister($event)"
          [(ngModel)]="settingIsPriceDownInCacheRegister"></p-inputSwitch>
      </div>

      <div class="col-12">メモ</div>
      <div class="col-12">
        <textarea [(ngModel)]="settingMemo" rows="5" cols="70" pInputTextarea [autoResize]="true"></textarea>
      </div>

      <div class="col-12">知財・真贋</div>
      <div class="col-12">
        <textarea [(ngModel)]="settingIpMemo" rows="5" cols="70" pInputTextarea [autoResize]="true"></textarea>
      </div>

      <div class="col-4">
        <!--
        <button
          pButton
          type="button"
          label="この商品を削除"
          class="p-button-sm p-button-danger"
          (click)="doDeletePricetar(selectedColumnId)"
        ></button>
        -->
      </div>
      <div class="col-4"></div>
      <div class="col-4">
        <button pButton type="button" label="設定する" class="p-button-sm" (click)="updatePricetarSetting()"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog header="商品詳細設定" [(visible)]="showSedoristModal" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-8">公開設定</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsPublic($event)" [(ngModel)]="settingPublic"></p-inputSwitch>
      </div>

      <div class="col-8">寝かせ</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsSleeped($event)" [(ngModel)]="settingIsSleeped"></p-inputSwitch>
      </div>

      <div class="col-8">レジ落ち</div>
      <div class="col-4">
        <p-inputSwitch (onChange)="setSettingIsPriceDownInCacheRegister($event)"
          [(ngModel)]="settingIsPriceDownInCacheRegister"></p-inputSwitch>
      </div>

      <div class="col-12">メモ</div>
      <div class="col-12">
        <textarea [(ngModel)]="settingMemo" rows="5" cols="70" pInputTextarea [autoResize]="true"></textarea>
      </div>

      <div class="col-12">知財・真贋</div>
      <div class="col-12">
        <textarea [(ngModel)]="settingIpMemo" rows="5" cols="70" pInputTextarea [autoResize]="true"></textarea>
      </div>

      <div class="col-4">
        <!--
        <button
          pButton
          type="button"
          label="この商品を削除"
          class="p-button-sm p-button-danger"
          (click)="doDeleteSedorist(selectedColumnId)"
        ></button>
        -->
      </div>
      <div class="col-4"></div>
      <div class="col-4">
        <button pButton type="button" label="設定する" class="p-button-sm" (click)="updateSedoristSetting()"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>