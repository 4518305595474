<p-button
  label="戻る"
  class="nav-button"
  styleClass="ui-button-secondary"
  icon="pi pi-arrow-left"
  iconPos="left"
  (click)="goBack()"
></p-button>

<br />
<br />

<p-tabView>
  <p-tabPanel header="プライスター">
    <textarea
      pInputTextarea
      [(ngModel)]="pricetar"
      style="width: 100%"
    ></textarea>
    <p-button
      label="確認"
      styleClass="ui-button-primary"
      icon="pi pi-check-square"
      iconPos="left"
      (click)="doPricetarCheck()"
      [disabled]="!!!this.pricetar"
    >
    </p-button>
    <span style="padding-left: 10px"></span>
    <p-button
      label="アップロード"
      styleClass="ui-button-success"
      icon="pi pi-upload"
      iconPos="left"
      (click)="doUploadPriceter()"
      [disabled]="!(this.pricetars && this.pricetars.length > 0)"
    >
    </p-button>
  </p-tabPanel>

  <p-tabPanel header="セドリスト">
    <div class="grid">
      <div class="col-12">
        <p-radioButton
          name="false"
          value="false"
          [(ngModel)]="isSedoristSearchList"
          inputId="false"
        ></p-radioButton>
        <label for="false">ウォッチリスト/仕入れリスト </label>
        <p-radioButton
          name="true"
          value="true"
          [(ngModel)]="isSedoristSearchList"
          inputId="true"
        ></p-radioButton>
        <label for="true">検索リスト</label>
      </div>
    </div>

    <textarea
      pInputTextarea
      [(ngModel)]="sedorist"
      style="width: 100%"
    ></textarea>
    <p-button
      label="確認"
      styleClass="ui-button-primary"
      icon="pi pi-check-square"
      iconPos="left"
      (click)="doSedoristCheck()"
      [disabled]="!!!this.sedorist"
    >
    </p-button>
    <span style="padding-left: 10px"></span>
    <p-button
      label="アップロード"
      styleClass="ui-button-success"
      icon="pi pi-upload"
      iconPos="left"
      (click)="doUploadSedorist()"
      [disabled]="!(this.sedorists && this.sedorists.length > 0)"
    >
    </p-button>
  </p-tabPanel>
</p-tabView>

<div *ngIf="hasPriceter()">
  全{{ pricetars.length.toLocaleString() }}件
  <p-table
    [value]="pricetars"
    [resizableColumns]="true"
    columnResizeMode="expand"
    styleClass="p-datatable-gridlines"
    class="share-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>ASIN</th>
        <th>SKU</th>
        <th>商品名</th>
        <th pSortableColumn="buyingPrice">
          仕入値<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th pSortableColumn="sellingPrice">
          売価<p-sortIcon field="sellingPrice"></p-sortIcon>
        </th>
        <th pSortableColumn="costPrice">
          手数料<p-sortIcon field="costPrice"></p-sortIcon>
        </th>
        <th>配送経路</th>
        <th>状態</th>
        <th pSortableColumn="orderDate">
          注文日<p-sortIcon field="orderDate"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-priceter>
      <tr>
        <td>{{ priceter.asin }}</td>
        <td>{{ priceter.sku }}</td>
        <td>{{ priceter.name }}</td>
        <td>{{ priceter.buyingPrice.toLocaleString() }}</td>
        <td>{{ priceter.sellingPrice.toLocaleString() }}</td>
        <td>{{ priceter.costPrice.toLocaleString() }}</td>
        <td>{{ priceter.deliveryRoute }}</td>
        <td>{{ priceter.condition }}</td>
        <td>{{ priceter.orderDate | japanDate }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="hasSedorist()">
  全{{ sedorists.length.toLocaleString() }}件
  <p-table
    [value]="sedorists"
    [resizableColumns]="true"
    columnResizeMode="expand"
    styleClass="p-datatable-gridlines"
    class="share-table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>ASIN</th>
        <th>JAN</th>
        <th>SKU</th>
        <th>商品名</th>
        <th pSortableColumn="buyingPrice">
          仕入値<p-sortIcon field="buyingPrice"></p-sortIcon>
        </th>
        <th pSortableColumn="sellingPrice">
          出品予定価格<p-sortIcon field="sellingPrice"></p-sortIcon>
        </th>
        <th>状態</th>
        <th>配送経路</th>
        <th pSortableColumn="buyingDate">
          仕入日<p-sortIcon field="buyingDate"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sedorist>
      <tr>
        <td>{{ sedorist.asin }}</td>
        <td>{{ sedorist.jan }}</td>
        <td>{{ sedorist.sku }}</td>
        <td>{{ sedorist.name }}</td>
        <td>{{ sedorist.buyingPrice.toLocaleString() }}</td>
        <td>{{ sedorist.sellingPrice.toLocaleString() }}</td>
        <td>{{ sedorist.condition }}</td>
        <td>{{ sedorist.deliveryRoute }}</td>
        <td>{{ sedorist.buyingDate | japanDate }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
