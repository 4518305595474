export class User {
    public email: string;
    public userRole: string;
    public isStoreManager: boolean;
    public userSubRoles: Array<string>;
    public token: string;
    public storeType: string;
    public storeTime: Date;
    public functions: Array<string>;
}
