import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubtitleService {
  private subtitle: string;
  public subtitleSubject = new Subject<string>();
  public subtitleState = this.subtitleSubject.asObservable();

  constructor() { }

  setSubTitle(s: string) {
    this.subtitle = s;
    this.subtitleSubject.next(s);
  }
}
