import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import {
  ShopGQL,
  ShopType,
  Shop,
  UpdateShopProductsMutationVariables,
  UpdateShopProductsMutation,
  UpdateShopProductsGQL,
  UserUpdateShop,
  UserUpdateShopGQL,
} from "src/gql/generated";

@Injectable({
  providedIn: "root",
})
export class ShopService {
  constructor(
    private http: HttpService,
    private shopGQL: ShopGQL,
    private userUpdateShopGQL: UserUpdateShopGQL,
    private updateShopProductsGQL: UpdateShopProductsGQL
  ) {}

  // shop情報の取得
  public async fetchShop(
    shopType: ShopType,
    shopCode: string,
    success: (resp: Shop) => void,
    error?: any
  ) {
    const body = { shopType, shopCode };
    const resp = await this.shopGQL
      .fetch(body, { fetchPolicy: "network-only" })
      .toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.shop);
    }
  }

  public async fetchUserUpdateShop(
    success: (resp: UserUpdateShop) => void,
    error?: any
  ) {
    const resp = await this.userUpdateShopGQL
      .fetch({}, { fetchPolicy: "network-only" })
      .toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && typeof error === "function") {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data.userUpdateShop);
    }
  }

  public async updateShopProducts(
    body: UpdateShopProductsMutationVariables,
    success: (resp: UpdateShopProductsMutation) => void,
    error?: any
  ) {
    const resp = await this.updateShopProductsGQL
      .mutate(body, { fetchPolicy: "no-cache" })
      .toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }
}
