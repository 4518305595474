<h1>らくじゃん更新履歴</h1>
<p-timeline
  [value]="histories"
  align="alternate"
  styleClass="customized-timeline"
>
  <ng-template pTemplate="marker" let-history>
    <span
      class="custom-marker shadow-2"
      [style.backgroundColor]="history.color"
    >
      <i [ngClass]="history.icon"></i>
    </span>
  </ng-template>
  <ng-template pTemplate="content" let-history>
    <p-card [header]="history.title" [subheader]="history.date">
      <p>{{ history.contents }}</p>
    </p-card>
  </ng-template>
</p-timeline>
