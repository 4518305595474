import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'japanDate'
})
export class JapanDatePipe implements PipeTransform {

  transform(date: number): string {
    if (!date) {
      return '-';
    }
    moment.locale('ja');
    return moment(date).format('l');
  }

}
