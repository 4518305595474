<nav class="navbar">
  <p-button
    *ngIf="userService.isLogined()"
    label="ログアウト"
    class="nav-button"
    styleClass="p-button-info p-button-sm"
    icon="pi pi-sign-out"
    iconPos="left"
    (click)="logout()"
  ></p-button>
  <!-- <p-button *ngIf="!userService.isLogined()" label="新規登録" class="nav-button" styleClass="p-button-secondary"
        icon="pi pi-user" iconPos="left" (click)="goRegister()"></p-button> -->
  <p-button
    *ngIf="!userService.isLogined()"
    label="ログイン"
    class="nav-button"
    styleClass="p-button-info p-button-sm"
    icon="pi pi-sign-in"
    iconPos="left"
    (click)="goLogin()"
  ></p-button>
  <span class="clickable logo" routerLink="/">
    らくじゃん
    <span *ngIf="!!subtitle" class="subtitle"></span>
    - {{ subtitle }}</span
  >
</nav>
<ng-http-loader
  [backdrop]="false"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="0.6"
  [spinner]="spinkit.skDoubleBounce"
>
</ng-http-loader>
<app-flash-message></app-flash-message>
<div class="container">
  <router-outlet></router-outlet>
</div>
