import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { MessageService } from "src/app/services/message.service";
import { RoutingService } from "src/app/services/routing.service";
import { UserService } from "src/app/services/user.service";
import { ExchangeRefreshTokenGQL } from "src/gql/generated";

@Component({
  selector: "app-callback",
  templateUrl: "./callback.component.html",
  styleUrls: ["./callback.component.sass"],
})
export class CallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private gql: ExchangeRefreshTokenGQL,
    private message: MessageService,
  ) {
    if (!this.userService.isLogined()) {
      this.message.setFlashMessageWithClear("error", "エラー", "らくじゃんにログインしてからAmazon連携を行ってください");
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const sellingPartnerID = params['selling_partner_id'];
      const spapiOauthCode = params['spapi_oauth_code'];
      const state = params['state'];
      firstValueFrom(this.gql.mutate({
        sellingPartnerID,
        spapiOauthCode,
        state,
      })).then(resp => {
        if (resp.errors) {
          console.error(resp);
          this.message.setFlashMessageWithClear("error", "エラー", "予期せぬエラーが発生しました。もう一度最初からやり直してください");
        } else {
          this.message.setFlashMessageWithClear("info", "成功", "Amazon連携に成功しました");
        }
        this.router.navigate(['/']);
      });
    });
  }
}
