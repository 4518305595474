import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BuyingStoreService } from "src/app/services/buying-store.service";
import { RoutingService } from "src/app/services/routing.service";
import { SubtitleService } from "src/app/services/subtitle.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.sass"],
})
export class StoreComponent implements OnInit {
  types: Array<BuyingStoreType> = [];

  displayTypeDialog = false;
  categoryName: string;

  displayStoreDialog = false;
  tempTypeId: number;
  storeName: string;

  displayDeleteStoreDialog = false;
  deleteStoreId: number;
  deleteStoreName: string;

  draggedStore: BuyingStore;
  draggedType: BuyingStoreType;

  tooltipMessage = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private userService: UserService,
    private buyingStoreService: BuyingStoreService
  ) {
    this.routingService.loginRequired();
    this.userService.shareRequired();
    this.subtitleService.setSubTitle("商品共有 - ストア管理");
  }

  ngOnInit() {
    this.loadBuyingStoreTypes();
  }

  onTabOpen(e) {
    console.log(e);
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: { i: e.index },
    });
  }

  canDeleteType(type: BuyingStoreType): boolean {
    if (type.stores && type.stores.length > 0) {
      return false;
    }
    return true;
  }

  loadBuyingStoreTypes() {
    this.types = [];
    this.buyingStoreService.fetchTypes((types) => {
      types.buyingStoreTypes.forEach((t) => {
        const stores = [];
        t.buyingStores.forEach((s) => {
          stores.push({
            id: s.id,
            name: s.name,
          });
        });
        this.types.push({
          id: t.id,
          name: t.name,
          stores,
        });
      });
    });
  }

  dragStart(type: BuyingStoreType, store: BuyingStore) {
    this.tooltipMessage = "入替先の店舗にドラッグ";
    this.draggedType = type;
    this.draggedStore = store;
  }

  dragEnd(droppedStore: BuyingStore) {
    console.log("dragEnd", droppedStore);

    this.tooltipMessage = "";
    this.draggedStore = null;
    this.draggedType = null;
  }

  drop(droppedStore: BuyingStore) {
    this.reorderStore(droppedStore);
    const t = this.draggedType;
    const ids = t.stores.map((s) => s.id);
    const body = { typeId: t.id, ids };
    this.buyingStoreService.updateBuyingStoreOrder(body, (resp) => { });
    console.log("drop: ", body);
  }

  dragLeave() {
    this.tooltipMessage = "範囲外";
  }

  dragEnter(enterStore: BuyingStore) {
    this.tooltipMessage = "移動先: " + enterStore.name;
  }

  isFirstType(type: BuyingStoreType): boolean {
    if (this.types === null || this.types.length === 0) {
      return false;
    }
    return this.types[0].id === type.id;
  }

  isLastType(type: BuyingStoreType): boolean {
    if (this.types === null || this.types.length === 0) {
      return false;
    }
    const lastIndex = this.types.length - 1;
    return this.types[lastIndex].id === type.id;
  }

  movePrev(type: BuyingStoreType) {
    const targetIndex = this.types.findIndex((t) => t.id === type.id);
    const prevIndex = targetIndex - 1;
    [this.types[targetIndex], this.types[prevIndex]] = [
      this.types[prevIndex],
      this.types[targetIndex],
    ];
    this.updateStoreType();
  }

  moveNext(type: BuyingStoreType) {
    const targetIndex = this.types.findIndex((t) => t.id === type.id);
    const nextIndex = targetIndex + 1;
    [this.types[targetIndex], this.types[nextIndex]] = [
      this.types[nextIndex],
      this.types[targetIndex],
    ];
    this.updateStoreType();
  }

  private updateStoreType() {
    const ids = this.types.map((t) => t.id);
    this.buyingStoreService.updateBuyingStoreTypeOrder({ ids }, (resp) => { });
  }

  private reorderStore(enterStore: BuyingStore) {
    if (!this.draggedType || !this.draggedStore) {
      return;
    }
    const idxDragged = this.draggedType.stores.findIndex(
      (s) => s.id === this.draggedStore.id
    );
    const idxEnter = this.draggedType.stores.findIndex(
      (s) => s.id === enterStore.id
    );
    this.draggedType.stores.splice(idxDragged, 1);
    this.draggedType.stores.splice(idxEnter, 0, this.draggedStore);
  }

  dropDeleteStore() {
    this.showDeleteStoreDialog(this.draggedStore.id, this.draggedStore.name);
  }

  goBack() {
    this.router.navigate(["/share"]);
  }

  showTypeDialog() {
    this.displayTypeDialog = true;
  }

  showStoreDialog(typeId: number) {
    this.tempTypeId = typeId;
    this.displayStoreDialog = true;
  }

  showDeleteStoreDialog(storeId: number, storeName: string) {
    this.displayDeleteStoreDialog = true;
    this.deleteStoreId = storeId;
    this.deleteStoreName = storeName;
  }

  registerStoreType() {
    this.buyingStoreService.registerType(
      { name: this.categoryName },
      (resp) => {
        this.loadBuyingStoreTypes();
        this.displayTypeDialog = false;
      }
    );
  }

  registerStore() {
    this.buyingStoreService.register(
      { name: this.storeName, typeId: this.tempTypeId },
      (resp) => {
        this.loadBuyingStoreTypes();
        this.storeName = null;
        this.tempTypeId = null;
        this.displayStoreDialog = false;
      }
    );
  }

  deleteType(id: number) {
    this.buyingStoreService.deleteType({ id }, (resp) => {
      this.loadBuyingStoreTypes();
    });
  }

  deleteStore() {
    this.buyingStoreService.delete({ id: this.deleteStoreId }, (resp) => {
      this.loadBuyingStoreTypes();
      this.displayDeleteStoreDialog = false;
    });
  }
}

interface BuyingStore {
  id: number;
  name: string;
}

interface BuyingStoreType {
  id: number;
  name: string;
  stores: Array<BuyingStore>;
}
