import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.sass']
})
export class BadgesComponent {
  @Input() notices: any[];

  getType(t: string): string {
    switch (t) {
      case "is_minus_40":
        return "40%以上減"
      case "is_plus_30":
        return "30%以上増"
      case "is_minus_seller":
        return "出品者減"
      case "is_zero_seller":
        return "出品者0"
      case "is_return_seller":
        return "出品者復活"
      case "is_return_amazon":
        return "Amazon復活"
      case "is_zero_amazon":
        return "Amazon0"
      case "is_minus_40_amazon":
        return "Amazon40%以上減"
      case "is_plus_30_amazon":
        return "Amazon30%以上増"
      default:
        return "N/A";
    }
  }
}
