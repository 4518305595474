export class ErrorCode {
    public static readonly ValidationError = 'W0001';
    public static readonly InternalServerError = 'E0001';
    public static readonly AlreadyRegisteredEmailError = 'E0002';
    public static readonly InvalidEmailOrPassword = 'E0003';
    public static readonly AuthenticateError = 'E0005';
    public static readonly UnAuthorizeRequest = 'E0006';
    public static readonly MWSKeyError = 'E0010';
    public static readonly NotMatchSaltKey = 'E0011';
    public static readonly AlreadyUpdatingProduct = 'E0013';
    public static readonly NotFound404 = 'S0404';
}
