import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AmazonCategory = {
  __typename?: 'AmazonCategory';
  id: Scalars['Int']['output'];
  isTop: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type AmazonNoticeProduct = {
  __typename?: 'AmazonNoticeProduct';
  amazonNotifications: Array<AmazonNotification>;
  amazonProduct: AmazonProduct;
  product: Product;
};

export type AmazonNotification = {
  __typename?: 'AmazonNotification';
  createdAt: Scalars['String']['output'];
  detail?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  notificationType: Scalars['String']['output'];
  notifiedAt: Scalars['String']['output'];
};

export type AmazonPriceTrack = {
  __typename?: 'AmazonPriceTrack';
  amazonProduct: AmazonProduct;
  id: Scalars['Int']['output'];
  threasholdPrice: Scalars['Int']['output'];
};

export type AmazonProduct = {
  __typename?: 'AmazonProduct';
  asin: Scalars['String']['output'];
  categoryFee?: Maybe<Scalars['Int']['output']>;
  fbaShippingFee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isMain: Scalars['Boolean']['output'];
  latestPrice: Scalars['Int']['output'];
  latestRanking: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  newSellerNum: Scalars['Int']['output'];
  oldSellerNum: Scalars['Int']['output'];
  salesBeginDate?: Maybe<Scalars['String']['output']>;
  salesCommission?: Maybe<Scalars['Int']['output']>;
  topCategory?: Maybe<Scalars['String']['output']>;
};

export type AssertBiccameraProduct = {
  __typename?: 'AssertBiccameraProduct';
  canRegisterNewItem: Scalars['Boolean']['output'];
};

export type AssertKojimaProduct = {
  __typename?: 'AssertKojimaProduct';
  canRegisterNewItem: Scalars['Boolean']['output'];
};

export type BiccameraProduct = {
  __typename?: 'BiccameraProduct';
  biccameraItemId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastCheckedAt: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Product;
  productName: Scalars['String']['output'];
  stocks?: Maybe<Array<Maybe<BiccameraStock>>>;
  userBiccameraProduct?: Maybe<UserBiccameraProduct>;
};

export type BiccameraStock = {
  __typename?: 'BiccameraStock';
  areaName: Scalars['String']['output'];
  hasStock: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  prefecture: Scalars['String']['output'];
  stockMark?: Maybe<Scalars['String']['output']>;
  storeName: Scalars['String']['output'];
};

export type BuyingStore = {
  __typename?: 'BuyingStore';
  buyingStoreType: BuyingStoreType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BuyingStoreType = {
  __typename?: 'BuyingStoreType';
  buyingStores?: Maybe<Array<Maybe<BuyingStore>>>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum Condition {
  New = 'new',
  Old = 'old'
}

export enum FilterOtherSetting {
  HasIpMemo = 'hasIPMemo',
  HasMemo = 'hasMemo',
  IsPriceDownInCacheRegister = 'isPriceDownInCacheRegister',
  IsSleeped = 'isSleeped'
}

export enum FilterUserProduct {
  All = 'all',
  My = 'my',
  Nonestore = 'nonestore'
}

export enum IrisOrderLevel {
  Direct = 'direct',
  Direct1 = 'direct1',
  Direct3 = 'direct3',
  Direct10 = 'direct10',
  DirectNoneSale = 'directNoneSale',
  DirectOver10 = 'directOver10',
  Discontinued = 'discontinued',
  Fba = 'fba',
  FbaConsider = 'fbaConsider',
  NoneCatalog = 'noneCatalog',
  NoneProfit = 'noneProfit',
  NoneStock = 'noneStock',
  Other = 'other',
  ScheduledDiscontinue = 'scheduledDiscontinue'
}

export type IrisProduct = {
  __typename?: 'IrisProduct';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  discontinuedSchedule?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  irisProductCode: Scalars['String']['output'];
  irisSalesResults?: Maybe<Array<Maybe<IrisSalesResult>>>;
  kilogram?: Maybe<Scalars['Float']['output']>;
  lastImportedAt: Scalars['String']['output'];
  latestHistory?: Maybe<IrisProduct>;
  lvonPrice: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  modelNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  orderLevel?: Maybe<Scalars['String']['output']>;
  outerCaseSizeD?: Maybe<Scalars['Int']['output']>;
  outerCaseSizeH?: Maybe<Scalars['Int']['output']>;
  outerCaseSizeW?: Maybe<Scalars['Int']['output']>;
  priceRevision?: Maybe<Scalars['String']['output']>;
  product: Product;
  quantity?: Maybe<Scalars['Int']['output']>;
  referencePrice?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['String']['output'];
  useFba: Scalars['Boolean']['output'];
};

export type IrisSalesResult = {
  __typename?: 'IrisSalesResult';
  id: Scalars['Int']['output'];
  salesMonth: Scalars['String']['output'];
  salesQuantity: Scalars['Int']['output'];
};

export type KojimaProduct = {
  __typename?: 'KojimaProduct';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastCheckedAt: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Product;
  productName: Scalars['String']['output'];
  stocks?: Maybe<Array<Maybe<KojimaStock>>>;
  userKojimaProduct?: Maybe<UserKojimaProduct>;
};

export type KojimaStock = {
  __typename?: 'KojimaStock';
  areaId: Scalars['String']['output'];
  areaName: Scalars['String']['output'];
  hasStock: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  prefecture: Scalars['String']['output'];
  stockValue: Scalars['String']['output'];
  storeName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteAmazonPriceTrack: Scalars['Boolean']['output'];
  deleteBiccameraProduct: Scalars['Boolean']['output'];
  deleteBuyingStore: Scalars['Boolean']['output'];
  deleteBuyingStoreType: Scalars['Boolean']['output'];
  deleteKojimaProduct: Scalars['Boolean']['output'];
  deleteUserPricetarProducts?: Maybe<Scalars['Boolean']['output']>;
  deleteUserSedoristProducts?: Maybe<Scalars['Boolean']['output']>;
  exchangeRefreshToken: Scalars['Boolean']['output'];
  refresh?: Maybe<Scalars['Boolean']['output']>;
  registerBuyingStore: Scalars['Int']['output'];
  registerBuyingStoreType: Scalars['Int']['output'];
  registerQueueBiccameraProduct: Scalars['Boolean']['output'];
  registerQueueKojimaProduct: Scalars['Boolean']['output'];
  registerUser: SessionToken;
  registerUserPricetarProducts?: Maybe<Scalars['Boolean']['output']>;
  registerUserSedoristProducts?: Maybe<Scalars['Boolean']['output']>;
  signin: SessionToken;
  signout?: Maybe<Scalars['Boolean']['output']>;
  updateBuyingStoreOrder: Scalars['Boolean']['output'];
  updateBuyingStoreTypeOrder: Scalars['Boolean']['output'];
  updateIrisFba: Scalars['Boolean']['output'];
  updateIrisMemo: Scalars['Boolean']['output'];
  updateIrisOrderLevel: Scalars['Boolean']['output'];
  updateShopProducts: Shop;
  updateUserBiccameraProduct: Scalars['Boolean']['output'];
  updateUserKojimaProduct: Scalars['Boolean']['output'];
  updateUserPricetarProducts: Scalars['Boolean']['output'];
  updateUserSedoristProducts: Scalars['Boolean']['output'];
  upsertAmazonPriceTrack: Scalars['Boolean']['output'];
};


export type MutationDeleteAmazonPriceTrackArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBiccameraProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBuyingStoreArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBuyingStoreTypeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteKojimaProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserPricetarProductsArgs = {
  input: Scalars['Int']['input'];
};


export type MutationDeleteUserSedoristProductsArgs = {
  input: Scalars['Int']['input'];
};


export type MutationExchangeRefreshTokenArgs = {
  sellingPartnerID: Scalars['String']['input'];
  spapiOauthCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};


export type MutationRegisterBuyingStoreArgs = {
  name: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};


export type MutationRegisterBuyingStoreTypeArgs = {
  name: Scalars['String']['input'];
};


export type MutationRegisterQueueBiccameraProductArgs = {
  itemID: Scalars['String']['input'];
};


export type MutationRegisterQueueKojimaProductArgs = {
  jan: Scalars['String']['input'];
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRegisterUserPricetarProductsArgs = {
  input?: InputMaybe<Array<UserPricetarProductInput>>;
};


export type MutationRegisterUserSedoristProductsArgs = {
  input?: InputMaybe<Array<UserSedoristProductInput>>;
};


export type MutationSigninArgs = {
  input: SigninInput;
};


export type MutationUpdateBuyingStoreOrderArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  typeId: Scalars['Int']['input'];
};


export type MutationUpdateBuyingStoreTypeOrderArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationUpdateIrisFbaArgs = {
  id: Scalars['Int']['input'];
  useFba: Scalars['Boolean']['input'];
};


export type MutationUpdateIrisMemoArgs = {
  id: Scalars['Int']['input'];
  memo: Scalars['String']['input'];
};


export type MutationUpdateIrisOrderLevelArgs = {
  id: Scalars['Int']['input'];
  orderLevel: IrisOrderLevel;
};


export type MutationUpdateShopProductsArgs = {
  input: UpdateShopProductInput;
};


export type MutationUpdateUserBiccameraProductArgs = {
  anotherPrice: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateUserKojimaProductArgs = {
  anotherPrice: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateUserPricetarProductsArgs = {
  buyingStoreId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  ipMemo?: InputMaybe<Scalars['String']['input']>;
  isPriceDownInCacheRegister?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSleeped?: InputMaybe<Scalars['Boolean']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserSedoristProductsArgs = {
  buyingStoreId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  ipMemo?: InputMaybe<Scalars['String']['input']>;
  isPriceDownInCacheRegister?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSleeped?: InputMaybe<Scalars['Boolean']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertAmazonPriceTrackArgs = {
  amazonProductId: Scalars['Int']['input'];
  thresholdPrice: Scalars['Int']['input'];
};

export type Product = {
  __typename?: 'Product';
  amazonProducts: Array<AmazonProduct>;
  id: Scalars['ID']['output'];
  jan: Scalars['String']['output'];
  modelNumber?: Maybe<Scalars['String']['output']>;
  rakutenProducts: Array<RakutenProduct>;
  referencePrice?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  amazonCategories?: Maybe<Array<Maybe<AmazonCategory>>>;
  amazonPriceTrack?: Maybe<Array<Maybe<AmazonPriceTrack>>>;
  assertBiccameraProducts?: Maybe<AssertBiccameraProduct>;
  assertKojimaProducts?: Maybe<AssertKojimaProduct>;
  biccameraProducts?: Maybe<Array<Maybe<BiccameraProduct>>>;
  buyingStoreTypes?: Maybe<Array<Maybe<BuyingStoreType>>>;
  buyingStores?: Maybe<Array<Maybe<BuyingStore>>>;
  irisProduct?: Maybe<Array<Maybe<IrisProduct>>>;
  kojimaProducts?: Maybe<Array<Maybe<KojimaProduct>>>;
  me: User;
  productDetails: Array<Product>;
  queueBiccameraProducts?: Maybe<Array<Maybe<QueueBiccameraProduct>>>;
  queueKojimaProducts?: Maybe<Array<Maybe<QueueKojimaProduct>>>;
  registerTokenValidate: RegisterSession;
  shop: Shop;
  userAmazonNoticeProducts: Array<AmazonNoticeProduct>;
  userPricetarProducts: Array<UserPricetarProduct>;
  userSedoristProducts: Array<UserSedoristProduct>;
  userUpdateShop?: Maybe<UserUpdateShop>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAmazonCategoriesArgs = {
  isTop?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProductDetailsArgs = {
  input: UpdateShopProductInput;
};


export type QueryRegisterTokenValidateArgs = {
  token: Scalars['String']['input'];
};


export type QueryShopArgs = {
  input: ShopSearchInput;
};


export type QueryUserPricetarProductsArgs = {
  input?: InputMaybe<UserProductInput>;
};


export type QueryUserSedoristProductsArgs = {
  input?: InputMaybe<UserProductInput>;
};


export type QueryUsersArgs = {
  serviceType: Scalars['String']['input'];
};

export type QueueBiccameraProduct = {
  __typename?: 'QueueBiccameraProduct';
  biccameraItemId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type QueueKojimaProduct = {
  __typename?: 'QueueKojimaProduct';
  createdAt: Scalars['String']['output'];
  jan: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RakutenProduct = {
  __typename?: 'RakutenProduct';
  hasStock: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  productCode: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RegisterSession = {
  __typename?: 'RegisterSession';
  email: Scalars['String']['output'];
};

export type RegisterUserInput = {
  password: Scalars['String']['input'];
  salt: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type SessionToken = {
  __typename?: 'SessionToken';
  token: Scalars['String']['output'];
};

export type Shop = {
  __typename?: 'Shop';
  isDoing: Scalars['Boolean']['output'];
  latestEndAt?: Maybe<Scalars['String']['output']>;
  latestMaxPrice?: Maybe<Scalars['Int']['output']>;
  latestMinPrice?: Maybe<Scalars['Int']['output']>;
  latestStartedAt?: Maybe<Scalars['String']['output']>;
  shopCode: Scalars['String']['output'];
  shopName: Scalars['String']['output'];
  shopUrl: Scalars['String']['output'];
  totalAmazonNum: Scalars['Int']['output'];
  totalProductNum: Scalars['Int']['output'];
  updatedAmazonNum: Scalars['Int']['output'];
  updatedProductNum: Scalars['Int']['output'];
};

export type ShopSearchInput = {
  shopCode: Scalars['String']['input'];
  shopType: ShopType;
};

export enum ShopType {
  Rakuten = 'rakuten',
  Yahoo = 'yahoo'
}

export type SigninInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  serviceType: Scalars['String']['input'];
};

export type UpdateShopProductInput = {
  hasNewProduct?: InputMaybe<Scalars['Boolean']['input']>;
  maxPrice: Scalars['Int']['input'];
  minGross?: InputMaybe<Scalars['Int']['input']>;
  minPrice: Scalars['Int']['input'];
  minProfitRate?: InputMaybe<Scalars['Int']['input']>;
  minRanking?: InputMaybe<Scalars['Int']['input']>;
  shopCode: Scalars['String']['input'];
  shopType: ShopType;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  functions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hasRefreshToken: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isStoreManager: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  telegramHashKey?: Maybe<Scalars['String']['output']>;
  userGroup: UserGroup;
  userRole: Scalars['String']['output'];
  userSubRoles: Array<Maybe<UserSubRole>>;
  userTelegram?: Maybe<UserTelegram>;
};

export type UserBiccameraProduct = {
  __typename?: 'UserBiccameraProduct';
  anotherPrice?: Maybe<Scalars['Int']['output']>;
  biccameraProductId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  name: Scalars['String']['output'];
};

export type UserKojimaProduct = {
  __typename?: 'UserKojimaProduct';
  anotherPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  kojimaProductId: Scalars['ID']['output'];
  user: User;
};

export type UserPricetarProduct = {
  __typename?: 'UserPricetarProduct';
  amazonProduct: AmazonProduct;
  buyingPrice: Scalars['Int']['output'];
  buyingStore?: Maybe<BuyingStore>;
  condition: Condition;
  createdAt: Scalars['String']['output'];
  deliveryRoute: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  ipMemo?: Maybe<Scalars['String']['output']>;
  isPriceDownInCacheRegister: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSleeped: Scalars['Boolean']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  orderDate: Scalars['String']['output'];
  product?: Maybe<Product>;
  productName: Scalars['String']['output'];
  salesCommission: Scalars['Int']['output'];
  sellingPrice: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  topCategory?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type UserPricetarProductInput = {
  asin: Scalars['String']['input'];
  buyingPrice: Scalars['Int']['input'];
  condition: Condition;
  deliveryRoute: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  orderDate: Scalars['String']['input'];
  productName: Scalars['String']['input'];
  salesCommission: Scalars['Int']['input'];
  sellingPrice: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type UserProductInput = {
  amazonCategoryId?: InputMaybe<Scalars['Int']['input']>;
  buyingStoreIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  buyingStoreTypeId?: InputMaybe<Scalars['Int']['input']>;
  filterOtherSetting?: InputMaybe<FilterOtherSetting>;
  filterUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filterUserProduct: FilterUserProduct;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type UserSedoristProduct = {
  __typename?: 'UserSedoristProduct';
  amazonProduct: AmazonProduct;
  buyingDate: Scalars['String']['output'];
  buyingPrice: Scalars['Int']['output'];
  buyingStore?: Maybe<BuyingStore>;
  condition: Condition;
  createdAt: Scalars['String']['output'];
  deliveryRoute: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  ipMemo?: Maybe<Scalars['String']['output']>;
  isPriceDownInCacheRegister: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSleeped: Scalars['Boolean']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productName: Scalars['String']['output'];
  sellingPrice: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  topCategory?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type UserSedoristProductInput = {
  asin: Scalars['String']['input'];
  buyingDate: Scalars['String']['input'];
  buyingPrice: Scalars['Int']['input'];
  condition: Condition;
  deliveryRoute: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  jan?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  sellingPrice: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export enum UserSubRole {
  Iris = 'iris',
  ProductShare = 'productShare'
}

export type UserTelegram = {
  __typename?: 'UserTelegram';
  chatID: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type UserUpdateShop = {
  __typename?: 'UserUpdateShop';
  shopCode: Scalars['String']['output'];
  shopType: Scalars['String']['output'];
};

export type RegisterTokenValidateQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type RegisterTokenValidateQuery = { __typename?: 'Query', registerTokenValidate: { __typename?: 'RegisterSession', email: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, userRole: string, telegramHashKey?: string | null, isStoreManager: boolean, hasRefreshToken: boolean, functions?: Array<string | null> | null, userSubRoles: Array<UserSubRole | null>, userGroup: { __typename?: 'UserGroup', name: string } } };

export type MeWithTelegramQueryVariables = Exact<{ [key: string]: never; }>;


export type MeWithTelegramQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, userRole: string, telegramHashKey?: string | null, isStoreManager: boolean, hasRefreshToken: boolean, userSubRoles: Array<UserSubRole | null>, userTelegram?: { __typename?: 'UserTelegram', id: number, firstName: string, lastName: string, chatID: string } | null } };

export type UsersQueryVariables = Exact<{
  serviceType: Scalars['String']['input'];
}>;


export type UsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: number, name?: string | null } | null> | null };

export type ShopQueryVariables = Exact<{
  shopType: ShopType;
  shopCode: Scalars['String']['input'];
}>;


export type ShopQuery = { __typename?: 'Query', shop: { __typename?: 'Shop', shopCode: string, shopName: string, shopUrl: string, latestStartedAt?: string | null, latestEndAt?: string | null, latestMinPrice?: number | null, latestMaxPrice?: number | null, totalProductNum: number, updatedProductNum: number, totalAmazonNum: number, updatedAmazonNum: number, isDoing: boolean } };

export type UserUpdateShopQueryVariables = Exact<{ [key: string]: never; }>;


export type UserUpdateShopQuery = { __typename?: 'Query', userUpdateShop?: { __typename?: 'UserUpdateShop', shopType: string, shopCode: string } | null };

export type ProductDetailsQueryVariables = Exact<{
  shopType: ShopType;
  shopCode: Scalars['String']['input'];
  minPrice: Scalars['Int']['input'];
  maxPrice: Scalars['Int']['input'];
  minGross?: InputMaybe<Scalars['Int']['input']>;
  minProfitRate?: InputMaybe<Scalars['Int']['input']>;
  hasNewProduct?: InputMaybe<Scalars['Boolean']['input']>;
  minRanking?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProductDetailsQuery = { __typename?: 'Query', productDetails: Array<{ __typename?: 'Product', jan: string, modelNumber?: string | null, referencePrice?: number | null, amazonProducts: Array<{ __typename?: 'AmazonProduct', name: string, asin: string, isMain: boolean, latestRanking: number, latestPrice: number, newSellerNum: number, oldSellerNum: number, salesBeginDate?: string | null, salesCommission?: number | null, categoryFee?: number | null, fbaShippingFee?: number | null }>, rakutenProducts: Array<{ __typename?: 'RakutenProduct', productCode: string, name: string, price: number, url: string, hasStock: boolean }> }> };

export type UserSedoristProductsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  filterUserProduct: FilterUserProduct;
  filterOtherSetting?: InputMaybe<FilterOtherSetting>;
  buyingStoreIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  buyingStoreTypeId?: InputMaybe<Scalars['Int']['input']>;
  filterUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  amazonCategoryId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UserSedoristProductsQuery = { __typename?: 'Query', userSedoristProducts: Array<{ __typename?: 'UserSedoristProduct', id: number, sku: string, productName: string, buyingPrice: number, sellingPrice: number, buyingDate: string, condition: Condition, deliveryRoute: string, memo?: string | null, ipMemo?: string | null, isPriceDownInCacheRegister: boolean, isSleeped: boolean, topCategory?: string | null, isPublic: boolean, createdAt: string, user: { __typename?: 'User', email: string, name?: string | null }, amazonProduct: { __typename?: 'AmazonProduct', asin: string, isMain: boolean, imageUrl?: string | null, salesCommission?: number | null, categoryFee?: number | null, fbaShippingFee?: number | null }, product?: { __typename?: 'Product', jan: string } | null, buyingStore?: { __typename?: 'BuyingStore', id: number, name: string, buyingStoreType: { __typename?: 'BuyingStoreType', id: number, name: string } } | null }> };

export type UserPricetarProductsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  filterUserProduct: FilterUserProduct;
  filterOtherSetting?: InputMaybe<FilterOtherSetting>;
  buyingStoreIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  buyingStoreTypeId?: InputMaybe<Scalars['Int']['input']>;
  filterUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  amazonCategoryId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UserPricetarProductsQuery = { __typename?: 'Query', userPricetarProducts: Array<{ __typename?: 'UserPricetarProduct', id: number, sku: string, productName: string, buyingPrice: number, sellingPrice: number, orderDate: string, deliveryRoute: string, memo?: string | null, ipMemo?: string | null, isPriceDownInCacheRegister: boolean, isSleeped: boolean, salesCommission: number, condition: Condition, topCategory?: string | null, isPublic: boolean, createdAt: string, user: { __typename?: 'User', email: string, name?: string | null }, amazonProduct: { __typename?: 'AmazonProduct', asin: string, isMain: boolean, imageUrl?: string | null, salesCommission?: number | null, categoryFee?: number | null, fbaShippingFee?: number | null }, product?: { __typename?: 'Product', jan: string } | null, buyingStore?: { __typename?: 'BuyingStore', id: number, name: string, buyingStoreType: { __typename?: 'BuyingStoreType', id: number, name: string } } | null }> };

export type IrisProductQueryVariables = Exact<{ [key: string]: never; }>;


export type IrisProductQuery = { __typename?: 'Query', irisProduct?: Array<{ __typename?: 'IrisProduct', id: number, irisProductCode: string, name: string, modelNumber?: string | null, color?: string | null, referencePrice?: number | null, lvonPrice: number, quantity?: number | null, orderLevel?: string | null, outerCaseSizeW?: number | null, outerCaseSizeD?: number | null, outerCaseSizeH?: number | null, kilogram?: number | null, discontinuedSchedule?: string | null, note?: string | null, memo?: string | null, priceRevision?: string | null, lastImportedAt: string, useFba: boolean, updatedAt: string, createdAt: string, irisSalesResults?: Array<{ __typename?: 'IrisSalesResult', id: number, salesMonth: string, salesQuantity: number } | null> | null, product: { __typename?: 'Product', jan: string, amazonProducts: Array<{ __typename?: 'AmazonProduct', asin: string, isMain: boolean, latestPrice: number, latestRanking: number, newSellerNum: number, salesCommission?: number | null, categoryFee?: number | null, fbaShippingFee?: number | null }> }, latestHistory?: { __typename?: 'IrisProduct', lvonPrice: number } | null } | null> | null };

export type BuyingStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type BuyingStoresQuery = { __typename?: 'Query', buyingStores?: Array<{ __typename?: 'BuyingStore', id: number, name: string, buyingStoreType: { __typename?: 'BuyingStoreType', id: number, name: string } } | null> | null };

export type BuyingStoreTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type BuyingStoreTypesQuery = { __typename?: 'Query', buyingStoreTypes?: Array<{ __typename?: 'BuyingStoreType', id: number, name: string, buyingStores?: Array<{ __typename?: 'BuyingStore', id: number, name: string } | null> | null } | null> | null };

export type AmazonCategoriesQueryVariables = Exact<{
  isTop?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AmazonCategoriesQuery = { __typename?: 'Query', amazonCategories?: Array<{ __typename?: 'AmazonCategory', id: number, name: string, isTop: boolean } | null> | null };

export type BiccameraProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type BiccameraProductsQuery = { __typename?: 'Query', queueBiccameraProducts?: Array<{ __typename?: 'QueueBiccameraProduct', biccameraItemId: string, createdAt: string } | null> | null, assertBiccameraProducts?: { __typename?: 'AssertBiccameraProduct', canRegisterNewItem: boolean } | null, biccameraProducts?: Array<{ __typename?: 'BiccameraProduct', id: string, productName: string, price: number, biccameraItemId: string, lastCheckedAt: string, createdAt: string, isDeleted: boolean, stocks?: Array<{ __typename?: 'BiccameraStock', areaName: string, prefecture: string, storeName: string, hasStock: boolean, stockMark?: string | null, note?: string | null } | null> | null, product: { __typename?: 'Product', jan: string }, userBiccameraProduct?: { __typename?: 'UserBiccameraProduct', id: string, anotherPrice?: number | null, user: { __typename?: 'User', id: number, name?: string | null } } | null } | null> | null };

export type KojimaProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type KojimaProductsQuery = { __typename?: 'Query', queueKojimaProducts?: Array<{ __typename?: 'QueueKojimaProduct', jan: string, createdAt: string } | null> | null, assertKojimaProducts?: { __typename?: 'AssertKojimaProduct', canRegisterNewItem: boolean } | null, kojimaProducts?: Array<{ __typename?: 'KojimaProduct', id: string, productName: string, price: number, lastCheckedAt: string, createdAt: string, isDeleted: boolean, stocks?: Array<{ __typename?: 'KojimaStock', areaId: string, areaName: string, prefecture: string, storeName: string, hasStock: boolean, stockValue: string, note?: string | null } | null> | null, product: { __typename?: 'Product', jan: string }, userKojimaProduct?: { __typename?: 'UserKojimaProduct', id: string, anotherPrice?: number | null, user: { __typename?: 'User', id: number, name?: string | null } } | null } | null> | null };

export type UserAmazonNoticeProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAmazonNoticeProductsQuery = { __typename?: 'Query', userAmazonNoticeProducts: Array<{ __typename?: 'AmazonNoticeProduct', amazonProduct: { __typename?: 'AmazonProduct', name: string, asin: string, isMain: boolean, imageUrl?: string | null, latestRanking: number, latestPrice: number, newSellerNum: number, oldSellerNum: number, salesBeginDate?: string | null, salesCommission?: number | null, categoryFee?: number | null, fbaShippingFee?: number | null, topCategory?: string | null }, product: { __typename?: 'Product', jan: string }, amazonNotifications: Array<{ __typename?: 'AmazonNotification', id: number, notifiedAt: string, notificationType: string, detail?: string | null, createdAt: string }> }> };

export type SigninMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  serviceType: Scalars['String']['input'];
}>;


export type SigninMutation = { __typename?: 'Mutation', signin: { __typename?: 'SessionToken', token: string } };

export type RegisterUserMutationVariables = Exact<{
  token: Scalars['String']['input'];
  salt: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser: { __typename?: 'SessionToken', token: string } };

export type SignoutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignoutMutation = { __typename?: 'Mutation', signout?: boolean | null };

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = { __typename?: 'Mutation', refresh?: boolean | null };

export type UpdateShopProductsMutationVariables = Exact<{
  shopType: ShopType;
  shopCode: Scalars['String']['input'];
  minPrice: Scalars['Int']['input'];
  maxPrice: Scalars['Int']['input'];
  minGross?: InputMaybe<Scalars['Int']['input']>;
  minProfitRate?: InputMaybe<Scalars['Int']['input']>;
  hasNewProduct?: InputMaybe<Scalars['Boolean']['input']>;
  minRanking?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateShopProductsMutation = { __typename?: 'Mutation', updateShopProducts: { __typename?: 'Shop', shopName: string, shopUrl: string, latestStartedAt?: string | null, latestEndAt?: string | null, totalProductNum: number } };

export type RegisterUserSedoristProductsMutationVariables = Exact<{
  userSedoristInputs?: InputMaybe<Array<UserSedoristProductInput> | UserSedoristProductInput>;
}>;


export type RegisterUserSedoristProductsMutation = { __typename?: 'Mutation', registerUserSedoristProducts?: boolean | null };

export type RegisterUserPricetarProductsMutationVariables = Exact<{
  userPricetarInputs?: InputMaybe<Array<UserPricetarProductInput> | UserPricetarProductInput>;
}>;


export type RegisterUserPricetarProductsMutation = { __typename?: 'Mutation', registerUserPricetarProducts?: boolean | null };

export type DeleteUserPricetarProductsMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUserPricetarProductsMutation = { __typename?: 'Mutation', deleteUserPricetarProducts?: boolean | null };

export type DeleteUserSedoristProductsMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUserSedoristProductsMutation = { __typename?: 'Mutation', deleteUserSedoristProducts?: boolean | null };

export type RegisterBuyingStoreMutationVariables = Exact<{
  name: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
}>;


export type RegisterBuyingStoreMutation = { __typename?: 'Mutation', registerBuyingStore: number };

export type RegisterBuyingStoreTypeMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type RegisterBuyingStoreTypeMutation = { __typename?: 'Mutation', registerBuyingStoreType: number };

export type UpdateUserSedoristProductMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  storeId?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  ipMemo?: InputMaybe<Scalars['String']['input']>;
  isPriceDownInCacheRegister?: InputMaybe<Scalars['Boolean']['input']>;
  isSleeped?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserSedoristProductMutation = { __typename?: 'Mutation', updateUserSedoristProducts: boolean };

export type UpdateUserPricetarProductMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  storeId?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  ipMemo?: InputMaybe<Scalars['String']['input']>;
  isPriceDownInCacheRegister?: InputMaybe<Scalars['Boolean']['input']>;
  isSleeped?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserPricetarProductMutation = { __typename?: 'Mutation', updateUserPricetarProducts: boolean };

export type DeleteBuyingStoreMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBuyingStoreMutation = { __typename?: 'Mutation', deleteBuyingStore: boolean };

export type DeleteBuyingStoreTypeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteBuyingStoreTypeMutation = { __typename?: 'Mutation', deleteBuyingStoreType: boolean };

export type UpdateBuyingStoreOrderMutationVariables = Exact<{
  typeId: Scalars['Int']['input'];
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type UpdateBuyingStoreOrderMutation = { __typename?: 'Mutation', updateBuyingStoreOrder: boolean };

export type UpdateBuyingStoreTypeOrderMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type UpdateBuyingStoreTypeOrderMutation = { __typename?: 'Mutation', updateBuyingStoreTypeOrder: boolean };

export type UpdateOrderLevelMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  orderLevel: IrisOrderLevel;
}>;


export type UpdateOrderLevelMutation = { __typename?: 'Mutation', updateIrisOrderLevel: boolean };

export type UpdateIrisMemoMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  memo: Scalars['String']['input'];
}>;


export type UpdateIrisMemoMutation = { __typename?: 'Mutation', updateIrisMemo: boolean };

export type RegsiterQueueBiccameraMutationVariables = Exact<{
  itemId: Scalars['String']['input'];
}>;


export type RegsiterQueueBiccameraMutation = { __typename?: 'Mutation', registerQueueBiccameraProduct: boolean };

export type UpdateUserBiccameraProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  anotherPrice: Scalars['Int']['input'];
}>;


export type UpdateUserBiccameraProductMutation = { __typename?: 'Mutation', updateUserBiccameraProduct: boolean };

export type RegsiterQueueKojimaMutationVariables = Exact<{
  jan: Scalars['String']['input'];
}>;


export type RegsiterQueueKojimaMutation = { __typename?: 'Mutation', registerQueueKojimaProduct: boolean };

export type UpdateUserKojimaProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  anotherPrice: Scalars['Int']['input'];
}>;


export type UpdateUserKojimaProductMutation = { __typename?: 'Mutation', updateUserKojimaProduct: boolean };

export type UpdateIrisFbaMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  useFba: Scalars['Boolean']['input'];
}>;


export type UpdateIrisFbaMutation = { __typename?: 'Mutation', updateIrisFba: boolean };

export type ExchangeRefreshTokenMutationVariables = Exact<{
  sellingPartnerID: Scalars['String']['input'];
  spapiOauthCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
}>;


export type ExchangeRefreshTokenMutation = { __typename?: 'Mutation', exchangeRefreshToken: boolean };

export type DeleteKojimaProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteKojimaProductMutation = { __typename?: 'Mutation', deleteKojimaProduct: boolean };

export type DeleteBiccameraProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteBiccameraProductMutation = { __typename?: 'Mutation', deleteBiccameraProduct: boolean };

export const RegisterTokenValidateDocument = gql`
    query registerTokenValidate($token: String!) {
  registerTokenValidate(token: $token) {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterTokenValidateGQL extends Apollo.Query<RegisterTokenValidateQuery, RegisterTokenValidateQueryVariables> {
    document = RegisterTokenValidateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  me {
    id
    userRole
    telegramHashKey
    isStoreManager
    hasRefreshToken
    functions
    userSubRoles
    userGroup {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeWithTelegramDocument = gql`
    query meWithTelegram {
  me {
    id
    userRole
    telegramHashKey
    isStoreManager
    hasRefreshToken
    userSubRoles
    userTelegram {
      id
      firstName
      lastName
      chatID
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeWithTelegramGQL extends Apollo.Query<MeWithTelegramQuery, MeWithTelegramQueryVariables> {
    document = MeWithTelegramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($serviceType: String!) {
  users(serviceType: $serviceType) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShopDocument = gql`
    query shop($shopType: ShopType!, $shopCode: String!) {
  shop(input: {shopType: $shopType, shopCode: $shopCode}) {
    shopCode
    shopName
    shopUrl
    latestStartedAt
    latestEndAt
    latestMinPrice
    latestMaxPrice
    totalProductNum
    updatedProductNum
    totalAmazonNum
    updatedAmazonNum
    isDoing
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShopGQL extends Apollo.Query<ShopQuery, ShopQueryVariables> {
    document = ShopDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUpdateShopDocument = gql`
    query userUpdateShop {
  userUpdateShop {
    shopType
    shopCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateShopGQL extends Apollo.Query<UserUpdateShopQuery, UserUpdateShopQueryVariables> {
    document = UserUpdateShopDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductDetailsDocument = gql`
    query productDetails($shopType: ShopType!, $shopCode: String!, $minPrice: Int!, $maxPrice: Int!, $minGross: Int, $minProfitRate: Int, $hasNewProduct: Boolean, $minRanking: Int) {
  productDetails(
    input: {shopType: $shopType, shopCode: $shopCode, minPrice: $minPrice, maxPrice: $maxPrice, minGross: $minGross, minProfitRate: $minProfitRate, hasNewProduct: $hasNewProduct, minRanking: $minRanking}
  ) {
    jan
    modelNumber
    referencePrice
    amazonProducts {
      name
      asin
      isMain
      latestRanking
      latestPrice
      newSellerNum
      oldSellerNum
      salesBeginDate
      salesCommission
      categoryFee
      fbaShippingFee
    }
    rakutenProducts {
      productCode
      name
      price
      url
      hasStock
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductDetailsGQL extends Apollo.Query<ProductDetailsQuery, ProductDetailsQueryVariables> {
    document = ProductDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserSedoristProductsDocument = gql`
    query userSedoristProducts($keyword: String, $fromDate: String, $toDate: String, $filterUserProduct: FilterUserProduct!, $filterOtherSetting: FilterOtherSetting, $buyingStoreIds: [Int], $buyingStoreTypeId: Int, $filterUserIds: [Int], $amazonCategoryId: Int) {
  userSedoristProducts(
    input: {keyword: $keyword, fromDate: $fromDate, toDate: $toDate, filterUserProduct: $filterUserProduct, filterOtherSetting: $filterOtherSetting, buyingStoreIds: $buyingStoreIds, buyingStoreTypeId: $buyingStoreTypeId, filterUserIds: $filterUserIds, amazonCategoryId: $amazonCategoryId}
  ) {
    id
    user {
      email
      name
    }
    amazonProduct {
      asin
      isMain
      imageUrl
      salesCommission
      categoryFee
      fbaShippingFee
    }
    product {
      jan
    }
    sku
    productName
    buyingPrice
    sellingPrice
    buyingDate
    buyingStore {
      id
      name
      buyingStoreType {
        id
        name
      }
    }
    condition
    deliveryRoute
    memo
    ipMemo
    isPriceDownInCacheRegister
    isSleeped
    topCategory
    isPublic
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSedoristProductsGQL extends Apollo.Query<UserSedoristProductsQuery, UserSedoristProductsQueryVariables> {
    document = UserSedoristProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPricetarProductsDocument = gql`
    query userPricetarProducts($keyword: String, $fromDate: String, $toDate: String, $filterUserProduct: FilterUserProduct!, $filterOtherSetting: FilterOtherSetting, $buyingStoreIds: [Int], $buyingStoreTypeId: Int, $filterUserIds: [Int], $amazonCategoryId: Int) {
  userPricetarProducts(
    input: {keyword: $keyword, fromDate: $fromDate, toDate: $toDate, filterUserProduct: $filterUserProduct, filterOtherSetting: $filterOtherSetting, buyingStoreIds: $buyingStoreIds, buyingStoreTypeId: $buyingStoreTypeId, filterUserIds: $filterUserIds, amazonCategoryId: $amazonCategoryId}
  ) {
    id
    user {
      email
      name
    }
    amazonProduct {
      asin
      isMain
      imageUrl
      salesCommission
      categoryFee
      fbaShippingFee
    }
    product {
      jan
    }
    sku
    productName
    buyingPrice
    sellingPrice
    orderDate
    deliveryRoute
    memo
    ipMemo
    isPriceDownInCacheRegister
    isSleeped
    buyingStore {
      id
      name
      buyingStoreType {
        id
        name
      }
    }
    salesCommission
    condition
    topCategory
    isPublic
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPricetarProductsGQL extends Apollo.Query<UserPricetarProductsQuery, UserPricetarProductsQueryVariables> {
    document = UserPricetarProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IrisProductDocument = gql`
    query irisProduct {
  irisProduct {
    id
    irisProductCode
    irisSalesResults {
      id
      salesMonth
      salesQuantity
    }
    name
    modelNumber
    color
    referencePrice
    lvonPrice
    quantity
    orderLevel
    outerCaseSizeW
    outerCaseSizeD
    outerCaseSizeH
    kilogram
    discontinuedSchedule
    note
    memo
    priceRevision
    lastImportedAt
    useFba
    updatedAt
    createdAt
    product {
      jan
      amazonProducts {
        asin
        isMain
        latestPrice
        latestRanking
        newSellerNum
        salesCommission
        categoryFee
        fbaShippingFee
      }
    }
    latestHistory {
      lvonPrice
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IrisProductGQL extends Apollo.Query<IrisProductQuery, IrisProductQueryVariables> {
    document = IrisProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BuyingStoresDocument = gql`
    query buyingStores {
  buyingStores {
    id
    name
    buyingStoreType {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BuyingStoresGQL extends Apollo.Query<BuyingStoresQuery, BuyingStoresQueryVariables> {
    document = BuyingStoresDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BuyingStoreTypesDocument = gql`
    query buyingStoreTypes {
  buyingStoreTypes {
    id
    name
    buyingStores {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BuyingStoreTypesGQL extends Apollo.Query<BuyingStoreTypesQuery, BuyingStoreTypesQueryVariables> {
    document = BuyingStoreTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AmazonCategoriesDocument = gql`
    query amazonCategories($isTop: Boolean) {
  amazonCategories(isTop: $isTop) {
    id
    name
    isTop
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AmazonCategoriesGQL extends Apollo.Query<AmazonCategoriesQuery, AmazonCategoriesQueryVariables> {
    document = AmazonCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BiccameraProductsDocument = gql`
    query biccameraProducts {
  queueBiccameraProducts {
    biccameraItemId
    createdAt
  }
  assertBiccameraProducts {
    canRegisterNewItem
  }
  biccameraProducts {
    id
    productName
    price
    biccameraItemId
    lastCheckedAt
    createdAt
    isDeleted
    stocks {
      areaName
      prefecture
      storeName
      hasStock
      stockMark
      note
    }
    product {
      jan
    }
    userBiccameraProduct {
      id
      anotherPrice
      user {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BiccameraProductsGQL extends Apollo.Query<BiccameraProductsQuery, BiccameraProductsQueryVariables> {
    document = BiccameraProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const KojimaProductsDocument = gql`
    query kojimaProducts {
  queueKojimaProducts {
    jan
    createdAt
  }
  assertKojimaProducts {
    canRegisterNewItem
  }
  kojimaProducts {
    id
    productName
    price
    lastCheckedAt
    createdAt
    isDeleted
    stocks {
      areaId
      areaName
      prefecture
      storeName
      hasStock
      stockValue
      note
    }
    product {
      jan
    }
    userKojimaProduct {
      id
      anotherPrice
      user {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KojimaProductsGQL extends Apollo.Query<KojimaProductsQuery, KojimaProductsQueryVariables> {
    document = KojimaProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserAmazonNoticeProductsDocument = gql`
    query userAmazonNoticeProducts {
  userAmazonNoticeProducts {
    amazonProduct {
      name
      asin
      isMain
      imageUrl
      latestRanking
      latestPrice
      newSellerNum
      oldSellerNum
      salesBeginDate
      salesCommission
      categoryFee
      fbaShippingFee
      topCategory
    }
    product {
      jan
    }
    amazonNotifications {
      id
      notifiedAt
      notificationType
      detail
      createdAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAmazonNoticeProductsGQL extends Apollo.Query<UserAmazonNoticeProductsQuery, UserAmazonNoticeProductsQueryVariables> {
    document = UserAmazonNoticeProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SigninDocument = gql`
    mutation signin($email: String!, $password: String!, $serviceType: String!) {
  signin(input: {email: $email, password: $password, serviceType: $serviceType}) {
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SigninGQL extends Apollo.Mutation<SigninMutation, SigninMutationVariables> {
    document = SigninDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterUserDocument = gql`
    mutation registerUser($token: String!, $salt: String!, $password: String!) {
  registerUser(input: {token: $token, salt: $salt, password: $password}) {
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserGQL extends Apollo.Mutation<RegisterUserMutation, RegisterUserMutationVariables> {
    document = RegisterUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignoutDocument = gql`
    mutation signout {
  signout
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignoutGQL extends Apollo.Mutation<SignoutMutation, SignoutMutationVariables> {
    document = SignoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RefreshDocument = gql`
    mutation refresh {
  refresh
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshGQL extends Apollo.Mutation<RefreshMutation, RefreshMutationVariables> {
    document = RefreshDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShopProductsDocument = gql`
    mutation updateShopProducts($shopType: ShopType!, $shopCode: String!, $minPrice: Int!, $maxPrice: Int!, $minGross: Int, $minProfitRate: Int, $hasNewProduct: Boolean, $minRanking: Int) {
  updateShopProducts(
    input: {shopType: $shopType, shopCode: $shopCode, minPrice: $minPrice, maxPrice: $maxPrice, minGross: $minGross, minProfitRate: $minProfitRate, hasNewProduct: $hasNewProduct, minRanking: $minRanking}
  ) {
    shopName
    shopUrl
    latestStartedAt
    latestEndAt
    totalProductNum
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShopProductsGQL extends Apollo.Mutation<UpdateShopProductsMutation, UpdateShopProductsMutationVariables> {
    document = UpdateShopProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterUserSedoristProductsDocument = gql`
    mutation registerUserSedoristProducts($userSedoristInputs: [UserSedoristProductInput!]) {
  registerUserSedoristProducts(input: $userSedoristInputs)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserSedoristProductsGQL extends Apollo.Mutation<RegisterUserSedoristProductsMutation, RegisterUserSedoristProductsMutationVariables> {
    document = RegisterUserSedoristProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterUserPricetarProductsDocument = gql`
    mutation registerUserPricetarProducts($userPricetarInputs: [UserPricetarProductInput!]) {
  registerUserPricetarProducts(input: $userPricetarInputs)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserPricetarProductsGQL extends Apollo.Mutation<RegisterUserPricetarProductsMutation, RegisterUserPricetarProductsMutationVariables> {
    document = RegisterUserPricetarProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserPricetarProductsDocument = gql`
    mutation deleteUserPricetarProducts($id: Int!) {
  deleteUserPricetarProducts(input: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserPricetarProductsGQL extends Apollo.Mutation<DeleteUserPricetarProductsMutation, DeleteUserPricetarProductsMutationVariables> {
    document = DeleteUserPricetarProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserSedoristProductsDocument = gql`
    mutation deleteUserSedoristProducts($id: Int!) {
  deleteUserSedoristProducts(input: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserSedoristProductsGQL extends Apollo.Mutation<DeleteUserSedoristProductsMutation, DeleteUserSedoristProductsMutationVariables> {
    document = DeleteUserSedoristProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterBuyingStoreDocument = gql`
    mutation registerBuyingStore($name: String!, $typeId: Int!) {
  registerBuyingStore(name: $name, typeId: $typeId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterBuyingStoreGQL extends Apollo.Mutation<RegisterBuyingStoreMutation, RegisterBuyingStoreMutationVariables> {
    document = RegisterBuyingStoreDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterBuyingStoreTypeDocument = gql`
    mutation registerBuyingStoreType($name: String!) {
  registerBuyingStoreType(name: $name)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterBuyingStoreTypeGQL extends Apollo.Mutation<RegisterBuyingStoreTypeMutation, RegisterBuyingStoreTypeMutationVariables> {
    document = RegisterBuyingStoreTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserSedoristProductDocument = gql`
    mutation updateUserSedoristProduct($id: Int!, $storeId: Int, $memo: String, $ipMemo: String, $isPriceDownInCacheRegister: Boolean, $isSleeped: Boolean, $isPublic: Boolean) {
  updateUserSedoristProducts(
    id: $id
    buyingStoreId: $storeId
    memo: $memo
    ipMemo: $ipMemo
    isPriceDownInCacheRegister: $isPriceDownInCacheRegister
    isSleeped: $isSleeped
    isPublic: $isPublic
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserSedoristProductGQL extends Apollo.Mutation<UpdateUserSedoristProductMutation, UpdateUserSedoristProductMutationVariables> {
    document = UpdateUserSedoristProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserPricetarProductDocument = gql`
    mutation updateUserPricetarProduct($id: Int!, $storeId: Int, $memo: String, $ipMemo: String, $isPriceDownInCacheRegister: Boolean, $isSleeped: Boolean, $isPublic: Boolean) {
  updateUserPricetarProducts(
    id: $id
    buyingStoreId: $storeId
    memo: $memo
    ipMemo: $ipMemo
    isPriceDownInCacheRegister: $isPriceDownInCacheRegister
    isSleeped: $isSleeped
    isPublic: $isPublic
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserPricetarProductGQL extends Apollo.Mutation<UpdateUserPricetarProductMutation, UpdateUserPricetarProductMutationVariables> {
    document = UpdateUserPricetarProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBuyingStoreDocument = gql`
    mutation deleteBuyingStore($id: Int!) {
  deleteBuyingStore(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBuyingStoreGQL extends Apollo.Mutation<DeleteBuyingStoreMutation, DeleteBuyingStoreMutationVariables> {
    document = DeleteBuyingStoreDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBuyingStoreTypeDocument = gql`
    mutation deleteBuyingStoreType($id: Int!) {
  deleteBuyingStoreType(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBuyingStoreTypeGQL extends Apollo.Mutation<DeleteBuyingStoreTypeMutation, DeleteBuyingStoreTypeMutationVariables> {
    document = DeleteBuyingStoreTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBuyingStoreOrderDocument = gql`
    mutation updateBuyingStoreOrder($typeId: Int!, $ids: [Int!]) {
  updateBuyingStoreOrder(typeId: $typeId, ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBuyingStoreOrderGQL extends Apollo.Mutation<UpdateBuyingStoreOrderMutation, UpdateBuyingStoreOrderMutationVariables> {
    document = UpdateBuyingStoreOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBuyingStoreTypeOrderDocument = gql`
    mutation updateBuyingStoreTypeOrder($ids: [Int!]) {
  updateBuyingStoreTypeOrder(ids: $ids)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBuyingStoreTypeOrderGQL extends Apollo.Mutation<UpdateBuyingStoreTypeOrderMutation, UpdateBuyingStoreTypeOrderMutationVariables> {
    document = UpdateBuyingStoreTypeOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrderLevelDocument = gql`
    mutation UpdateOrderLevel($id: Int!, $orderLevel: IrisOrderLevel!) {
  updateIrisOrderLevel(id: $id, orderLevel: $orderLevel)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderLevelGQL extends Apollo.Mutation<UpdateOrderLevelMutation, UpdateOrderLevelMutationVariables> {
    document = UpdateOrderLevelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIrisMemoDocument = gql`
    mutation updateIrisMemo($id: Int!, $memo: String!) {
  updateIrisMemo(id: $id, memo: $memo)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIrisMemoGQL extends Apollo.Mutation<UpdateIrisMemoMutation, UpdateIrisMemoMutationVariables> {
    document = UpdateIrisMemoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegsiterQueueBiccameraDocument = gql`
    mutation regsiterQueueBiccamera($itemId: String!) {
  registerQueueBiccameraProduct(itemID: $itemId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegsiterQueueBiccameraGQL extends Apollo.Mutation<RegsiterQueueBiccameraMutation, RegsiterQueueBiccameraMutationVariables> {
    document = RegsiterQueueBiccameraDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserBiccameraProductDocument = gql`
    mutation updateUserBiccameraProduct($id: ID!, $anotherPrice: Int!) {
  updateUserBiccameraProduct(id: $id, anotherPrice: $anotherPrice)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserBiccameraProductGQL extends Apollo.Mutation<UpdateUserBiccameraProductMutation, UpdateUserBiccameraProductMutationVariables> {
    document = UpdateUserBiccameraProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegsiterQueueKojimaDocument = gql`
    mutation regsiterQueueKojima($jan: String!) {
  registerQueueKojimaProduct(jan: $jan)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegsiterQueueKojimaGQL extends Apollo.Mutation<RegsiterQueueKojimaMutation, RegsiterQueueKojimaMutationVariables> {
    document = RegsiterQueueKojimaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserKojimaProductDocument = gql`
    mutation updateUserKojimaProduct($id: ID!, $anotherPrice: Int!) {
  updateUserKojimaProduct(id: $id, anotherPrice: $anotherPrice)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserKojimaProductGQL extends Apollo.Mutation<UpdateUserKojimaProductMutation, UpdateUserKojimaProductMutationVariables> {
    document = UpdateUserKojimaProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIrisFbaDocument = gql`
    mutation updateIrisFba($id: Int!, $useFba: Boolean!) {
  updateIrisFba(id: $id, useFba: $useFba)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIrisFbaGQL extends Apollo.Mutation<UpdateIrisFbaMutation, UpdateIrisFbaMutationVariables> {
    document = UpdateIrisFbaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExchangeRefreshTokenDocument = gql`
    mutation exchangeRefreshToken($sellingPartnerID: String!, $spapiOauthCode: String!, $state: String!) {
  exchangeRefreshToken(
    sellingPartnerID: $sellingPartnerID
    spapiOauthCode: $spapiOauthCode
    state: $state
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExchangeRefreshTokenGQL extends Apollo.Mutation<ExchangeRefreshTokenMutation, ExchangeRefreshTokenMutationVariables> {
    document = ExchangeRefreshTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteKojimaProductDocument = gql`
    mutation deleteKojimaProduct($id: ID!) {
  deleteKojimaProduct(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteKojimaProductGQL extends Apollo.Mutation<DeleteKojimaProductMutation, DeleteKojimaProductMutationVariables> {
    document = DeleteKojimaProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBiccameraProductDocument = gql`
    mutation deleteBiccameraProduct($id: ID!) {
  deleteBiccameraProduct(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBiccameraProductGQL extends Apollo.Mutation<DeleteBiccameraProductMutation, DeleteBiccameraProductMutationVariables> {
    document = DeleteBiccameraProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }